@import '~bootstrap/scss/bootstrap';
@import "assets/styles/fontawesome/css/all.css";
@import "assets/icons/flaticon-house/flaticon";
@import 'assets/styles/template1.scss';

html, body {
    height: 100%;
}

:root {
  --main-color: #fab100;
  --main-dark-color: #e6a100;
}

.select2{width: 100%;}
.select2 *:focus{outline: none!important;}

.select2-container { min-width: 150px; }

.select2-container--default .select2-results > .select2-results__options{max-height: 500px;}
.select2-container--default .select2-selection--single{background: none;border-radius: 0; border: none; height: auto;}
.select2-container--default .select2-selection--single .select2-selection__rendered{font-size: 13px; line-height: 19px; padding: 23px 20px 23px 20px; color: #333;}
.select2-container--default .select2-selection--single .select2-selection__arrow{display: none;}
.select2-dropdown{background: none;border-radius: 0; z-index: 100; min-width: 0px; border: none; box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.1); background: #fff;}
.select2-container--open .select2-dropdown--above{border-bottom: 2px solid var(--main-color); }
.select2-container--open .select2-dropdown--below{border-top: 2px solid var(--main-color); }

.select2-results__option{padding: 15px  30px; background: #fff; font-size: 13px; line-height: 20px; color: #333333; border-top: 1px solid #dddddd;}
.select2-results__option:first-child{border-top: none;}
.small .select2-results__option {
  padding-left: 0;
}
.select2-container--default .select2-results__option[aria-selected=true]{background: var(--main-color); color: #fff;}
.select2-container--default .select2-results__option--highlighted[aria-selected]{background: var(--main-color); color: #fff;}

.iti {
  width: 100%;
}

#loading-bar .bar {
  background: var(--main-color) !important;
}



.ng-dirty.ng-invalid {
    @extend .is-invalid;
}

.form-group .error {
    display: none;
}

.ng-dirty.ng-invalid + .error {
    display: block;
}