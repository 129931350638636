@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import "assets/styles/fontawesome/css/all.css";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .form-control.ng-dirty.ng-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .form-control.ng-dirty.ng-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.ng-dirty.ng-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.form-control.ng-dirty.ng-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid, textarea.form-control.ng-dirty.ng-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid, .custom-select.ng-dirty.ng-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus, .custom-select.ng-dirty.ng-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.ng-dirty.ng-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.custom-select.ng-dirty.ng-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.ng-dirty.ng-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip,
.form-control-file.ng-dirty.ng-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .form-check-input.ng-dirty.ng-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.ng-dirty.ng-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.form-check-input.ng-dirty.ng-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, .custom-control-input.ng-dirty.ng-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, .custom-control-input.ng-dirty.ng-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.ng-dirty.ng-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.custom-control-input.ng-dirty.ng-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, .custom-control-input.ng-dirty.ng-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .custom-control-input.ng-dirty.ng-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.ng-dirty.ng-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label, .custom-file-input.ng-dirty.ng-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.ng-dirty.ng-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.custom-file-input.ng-dirty.ng-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label, .custom-file-input.ng-dirty.ng-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }
  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*
Flaticon icon font: Flaticon
Creation date: 22/06/2016 15:07
*/
@font-face {
  font-family: "Flaticon";
  src: url("/assets/icons/flaticon-house/Flaticon.eot");
  src: url("/assets/icons/flaticon-house/Flaticon.eot?#iefix") format("embedded-opentype"), url("/assets/icons/flaticon-house/Flaticon.woff") format("woff"), url("/assets/icons/flaticon-house/Flaticon.ttf") format("truetype"), url("/assets/icons/flaticon-house/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("/assets/icons/flaticon-house/Flaticon.svg#Flaticon") format("svg");
  }
}
.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-antique-clock:before {
  content: "\f100";
}

.flaticon-armchair-with-wheels-of-studio-furniture:before {
  content: "\f101";
}

.flaticon-audio-equipment:before {
  content: "\f102";
}

.flaticon-baby-crib-bedroom-furniture:before {
  content: "\f103";
}

.flaticon-bathroom-black-bottle-container-with-spray-system:before {
  content: "\f104";
}

.flaticon-bathroom-bottle:before {
  content: "\f105";
}

.flaticon-bathroom-bottle-container-of-rounded-rectangular-black-shape:before {
  content: "\f106";
}

.flaticon-bathroom-brush:before {
  content: "\f107";
}

.flaticon-bathroom-brush-cleaning-tool:before {
  content: "\f108";
}

.flaticon-bathroom-closet-with-personal-care-objects:before {
  content: "\f109";
}

.flaticon-bathroom-detail:before {
  content: "\f10a";
}

.flaticon-bathroom-drainage-of-circular-shape:before {
  content: "\f10b";
}

.flaticon-bathroom-faucet:before {
  content: "\f10c";
}

.flaticon-bathroom-faucet-tool:before {
  content: "\f10d";
}

.flaticon-bathroom-first-aid-kit-box:before {
  content: "\f10e";
}

.flaticon-bathroom-furniture:before {
  content: "\f10f";
}

.flaticon-bathroom-furniture-1:before {
  content: "\f110";
}

.flaticon-bathroom-hanging-towel:before {
  content: "\f111";
}

.flaticon-bathroom-organization-furniture:before {
  content: "\f112";
}

.flaticon-bathroom-paper-roll:before {
  content: "\f113";
}

.flaticon-bathroom-spray-bottle:before {
  content: "\f114";
}

.flaticon-bathroom-tap-silhouette:before {
  content: "\f115";
}

.flaticon-bathroom-tap-tool-to-control-water-supply:before {
  content: "\f116";
}

.flaticon-bathroom-toothpaste-tube:before {
  content: "\f117";
}

.flaticon-bathroom-towel:before {
  content: "\f118";
}

.flaticon-bathroom-trash-container:before {
  content: "\f119";
}

.flaticon-bathtub-with-opened-shower:before {
  content: "\f11a";
}

.flaticon-bed-design-with-three-drawers:before {
  content: "\f11b";
}

.flaticon-bed-room-mirror-on-a-drawer-furniture:before {
  content: "\f11c";
}

.flaticon-bed-side-view:before {
  content: "\f11d";
}

.flaticon-bedroom-black-closed-closet-for-clothes:before {
  content: "\f11e";
}

.flaticon-bedroom-circular-alarm-clock-tool:before {
  content: "\f11f";
}

.flaticon-bedroom-closet-with-opened-door-of-the-side-to-hang-clothes:before {
  content: "\f120";
}

.flaticon-bedroom-drawer-furniture:before {
  content: "\f121";
}

.flaticon-bedroom-drawer-furniture-with-tv-monitor:before {
  content: "\f122";
}

.flaticon-bedroom-drawers-furniture:before {
  content: "\f123";
}

.flaticon-bedroom-furniture:before {
  content: "\f124";
}

.flaticon-bedroom-furniture-equipment:before {
  content: "\f125";
}

.flaticon-bedroom-furniture-small-table-for-bed-side:before {
  content: "\f126";
}

.flaticon-bedroom-hanger-for-clothes:before {
  content: "\f127";
}

.flaticon-bedroom-library-of-wheels-with-books:before {
  content: "\f128";
}

.flaticon-bedroom-mirror:before {
  content: "\f129";
}

.flaticon-bedroom-mirror-on-drawers-with-a-seat:before {
  content: "\f12a";
}

.flaticon-bedroom-rack-furniture-to-hang-things:before {
  content: "\f12b";
}

.flaticon-bedroom-stairs-tool:before {
  content: "\f12c";
}

.flaticon-bedroom-wardrobe:before {
  content: "\f12d";
}

.flaticon-big-luxury-door-entrance-architectural-detail:before {
  content: "\f12e";
}

.flaticon-black-bed:before {
  content: "\f12f";
}

.flaticon-black-box-for-storage:before {
  content: "\f130";
}

.flaticon-black-box-for-storage-and-organization-of-things:before {
  content: "\f131";
}

.flaticon-black-door:before {
  content: "\f132";
}

.flaticon-black-door-1:before {
  content: "\f133";
}

.flaticon-black-sofa-of-livingroom:before {
  content: "\f134";
}

.flaticon-bottle-opener-tool-for-kitchen:before {
  content: "\f135";
}

.flaticon-box-organization-tool:before {
  content: "\f136";
}

.flaticon-bricks-wall-with-grass-leaves-border:before {
  content: "\f137";
}

.flaticon-bubbles-bath-bathtub:before {
  content: "\f138";
}

.flaticon-building:before {
  content: "\f139";
}

.flaticon-bunk-bedroom-furniture:before {
  content: "\f13a";
}

.flaticon-candle-and-candelabrum-silhouette:before {
  content: "\f13b";
}

.flaticon-chair-with-wheels-of-studio-furniture:before {
  content: "\f13c";
}

.flaticon-children-bedroom-drawer-full-of-toys:before {
  content: "\f13d";
}

.flaticon-circular-wall-clock-for-livingroom:before {
  content: "\f13e";
}

.flaticon-circular-window:before {
  content: "\f13f";
}

.flaticon-cleaning-bottle-silhouette:before {
  content: "\f140";
}

.flaticon-cleaning-brush:before {
  content: "\f141";
}

.flaticon-cleaning-brush-tool:before {
  content: "\f142";
}

.flaticon-cleaning-container-tool:before {
  content: "\f143";
}

.flaticon-cleaning-mop:before {
  content: "\f144";
}

.flaticon-cleaning-mop-for-floors:before {
  content: "\f145";
}

.flaticon-cleaning-mop-house-tool:before {
  content: "\f146";
}

.flaticon-cleaning-pail-for-water:before {
  content: "\f147";
}

.flaticon-cleaning-spray-bottle:before {
  content: "\f148";
}

.flaticon-closed-bathroom-shower:before {
  content: "\f149";
}

.flaticon-clothes-hanger:before {
  content: "\f14a";
}

.flaticon-clothes-hanging-on-rope-for-drying:before {
  content: "\f14b";
}

.flaticon-clothespin:before {
  content: "\f14c";
}

.flaticon-coat-stand-livingroom-furniture:before {
  content: "\f14d";
}

.flaticon-corkscrew:before {
  content: "\f14e";
}

.flaticon-covered-barbecue:before {
  content: "\f14f";
}

.flaticon-cream-bottle-of-bathroom:before {
  content: "\f150";
}

.flaticon-cutting-tool-for-gardening:before {
  content: "\f151";
}

.flaticon-dark-door:before {
  content: "\f152";
}

.flaticon-desk-lamp-tool:before {
  content: "\f153";
}

.flaticon-dining-room-carpet:before {
  content: "\f154";
}

.flaticon-dining-room-chair:before {
  content: "\f155";
}

.flaticon-dining-room-cup-on-a-plate:before {
  content: "\f156";
}

.flaticon-dining-room-cupboard-with-glasses:before {
  content: "\f157";
}

.flaticon-dining-room-cutlery-set-of-three-pieces-in-silhouettes:before {
  content: "\f158";
}

.flaticon-dining-room-furniture:before {
  content: "\f159";
}

.flaticon-dining-room-furniture-of-a-table-with-chairs:before {
  content: "\f15a";
}

.flaticon-dining-room-furniture-to-storage-objects:before {
  content: "\f15b";
}

.flaticon-dining-room-table-eating-tools-set-from-top-view:before {
  content: "\f15c";
}

.flaticon-door:before {
  content: "\f15d";
}

.flaticon-door-design:before {
  content: "\f15e";
}

.flaticon-door-design-1:before {
  content: "\f15f";
}

.flaticon-door-of-arch-shape-with-two-small-trees-on-pots-at-both-sides:before {
  content: "\f160";
}

.flaticon-door-with-steps:before {
  content: "\f161";
}

.flaticon-drawer-rack-of-a-livingroom-with-books-and-a-bowl:before {
  content: "\f162";
}

.flaticon-dvd-player-tool:before {
  content: "\f163";
}

.flaticon-economic-architecture-building-of-stacked-containers:before {
  content: "\f164";
}

.flaticon-electric-heating-tool-with-water-system:before {
  content: "\f165";
}

.flaticon-electric-kettle:before {
  content: "\f166";
}

.flaticon-electric-lengthen:before {
  content: "\f167";
}

.flaticon-electric-outlet:before {
  content: "\f168";
}

.flaticon-electric-plugins-of-wall:before {
  content: "\f169";
}

.flaticon-electric-socket:before {
  content: "\f16a";
}

.flaticon-electric-socket-of-circular-shape-with-two-holes:before {
  content: "\f16b";
}

.flaticon-electric-socket-of-three-straight-holes:before {
  content: "\f16c";
}

.flaticon-electric-socket-of-two-straight-lines-holes:before {
  content: "\f16d";
}

.flaticon-electrical-circular-wall-connection:before {
  content: "\f16e";
}

.flaticon-electrical-socket-connector-of-three-holes:before {
  content: "\f16f";
}

.flaticon-electronic-house-tool-of-alarm:before {
  content: "\f170";
}

.flaticon-electronic-visualization-monitor-tool-for-tv-or-computer:before {
  content: "\f171";
}

.flaticon-elegant-door-entrance:before {
  content: "\f172";
}

.flaticon-elegant-door-of-entrance-with-columns:before {
  content: "\f173";
}

.flaticon-fan:before {
  content: "\f174";
}

.flaticon-fire-lighter:before {
  content: "\f175";
}

.flaticon-fireplace-with-fire-flames-heating-home:before {
  content: "\f176";
}

.flaticon-flowers-pot-of-yard:before {
  content: "\f177";
}

.flaticon-fluffy-puff:before {
  content: "\f178";
}

.flaticon-frying-pan:before {
  content: "\f179";
}

.flaticon-garage-drawer-furniture-for-tools-storage-and-organization:before {
  content: "\f17a";
}

.flaticon-garage-fixing-toolbox-set:before {
  content: "\f17b";
}

.flaticon-garage-lantern-tool:before {
  content: "\f17c";
}

.flaticon-garage-nut-tool-hexagonal-shape:before {
  content: "\f17d";
}

.flaticon-garage-saw-tool-sawing-a-table:before {
  content: "\f17e";
}

.flaticon-garage-table-with-drawers:before {
  content: "\f17f";
}

.flaticon-garage-with-opened-door-and-a-car-inside:before {
  content: "\f180";
}

.flaticon-garage-wrenches:before {
  content: "\f181";
}

.flaticon-gardening-shovel-tool-shape:before {
  content: "\f182";
}

.flaticon-glass:before {
  content: "\f183";
}

.flaticon-grass-leaves-silhouette:before {
  content: "\f184";
}

.flaticon-hairdryer:before {
  content: "\f185";
}

.flaticon-hammer-nailing-a-nail:before {
  content: "\f186";
}

.flaticon-hand-written-paper:before {
  content: "\f187";
}

.flaticon-hanging-cloth-towel-of-bathroom:before {
  content: "\f188";
}

.flaticon-home-building-like-a-birds-house:before {
  content: "\f189";
}

.flaticon-home-theatre-and-monitor-on-livingroom-furniture:before {
  content: "\f18a";
}

.flaticon-hose-watering-tool-for-gardening:before {
  content: "\f18b";
}

.flaticon-house-building:before {
  content: "\f18c";
}

.flaticon-house-building-1:before {
  content: "\f18d";
}

.flaticon-house-building-2:before {
  content: "\f18e";
}

.flaticon-house-building-beside-a-pine-tree:before {
  content: "\f18f";
}

.flaticon-house-building-front:before {
  content: "\f190";
}

.flaticon-house-construction:before {
  content: "\f191";
}

.flaticon-house-fireplace:before {
  content: "\f192";
}

.flaticon-house-front:before {
  content: "\f193";
}

.flaticon-house-front-1:before {
  content: "\f194";
}

.flaticon-house-front-of-three-floors:before {
  content: "\f195";
}

.flaticon-house-frontal-view-with-entrance-door-and-garage:before {
  content: "\f196";
}

.flaticon-house-security-keyhole-tool:before {
  content: "\f197";
}

.flaticon-house-stairs:before {
  content: "\f198";
}

.flaticon-house-with-one-frontal-window:before {
  content: "\f199";
}

.flaticon-iron:before {
  content: "\f19a";
}

.flaticon-jar-container-for-liquids:before {
  content: "\f19b";
}

.flaticon-key:before {
  content: "\f19c";
}

.flaticon-king-size-bedroom:before {
  content: "\f19d";
}

.flaticon-kitchen-bell-extractor:before {
  content: "\f19e";
}

.flaticon-kitchen-bowl:before {
  content: "\f19f";
}

.flaticon-kitchen-ceiling-lamp-of-semicircular-shape:before {
  content: "\f1a0";
}

.flaticon-kitchen-chair:before {
  content: "\f1a1";
}

.flaticon-kitchen-chair-1:before {
  content: "\f1a2";
}

.flaticon-kitchen-circular-ceramic-plates-stacked:before {
  content: "\f1a3";
}

.flaticon-kitchen-cloth-glove-to-protect-hand:before {
  content: "\f1a4";
}

.flaticon-kitchen-cups-set-hanging-of-a-rack:before {
  content: "\f1a5";
}

.flaticon-kitchen-electronic-furniture:before {
  content: "\f1a6";
}

.flaticon-kitchen-furniture:before {
  content: "\f1a7";
}

.flaticon-kitchen-grater-cutting-tool:before {
  content: "\f1a8";
}

.flaticon-kitchen-knife:before {
  content: "\f1a9";
}

.flaticon-kitchen-lighter-house-tool:before {
  content: "\f1aa";
}

.flaticon-kitchen-or-dining-room-door-with-oval-window:before {
  content: "\f1ab";
}

.flaticon-kitchen-palette-for-cooking:before {
  content: "\f1ac";
}

.flaticon-kitchen-small-bench:before {
  content: "\f1ad";
}

.flaticon-kitchen-spices-set-in-flasks-on-a-rack:before {
  content: "\f1ae";
}

.flaticon-kitchen-spoons-set:before {
  content: "\f1af";
}

.flaticon-kitchen-table-and-seats-set-of-furniture:before {
  content: "\f1b0";
}

.flaticon-lamp:before {
  content: "\f1b1";
}

.flaticon-lamp-1:before {
  content: "\f1b2";
}

.flaticon-leaves-of-garden:before {
  content: "\f1b3";
}

.flaticon-lightbulb:before {
  content: "\f1b4";
}

.flaticon-living-room-black-lamp-furniture-tool-for-illumination:before {
  content: "\f1b5";
}

.flaticon-living-room-books-group:before {
  content: "\f1b6";
}

.flaticon-livingroom-armchair:before {
  content: "\f1b7";
}

.flaticon-livingroom-armchair-1:before {
  content: "\f1b8";
}

.flaticon-livingroom-black-curtains:before {
  content: "\f1b9";
}

.flaticon-livingroom-black-double-sofa:before {
  content: "\f1ba";
}

.flaticon-livingroom-curtain-tool:before {
  content: "\f1bb";
}

.flaticon-livingroom-cushion-of-square-shape:before {
  content: "\f1bc";
}

.flaticon-livingroom-cushions-couple:before {
  content: "\f1bd";
}

.flaticon-livingroom-furniture:before {
  content: "\f1be";
}

.flaticon-livingroom-illumination-tool:before {
  content: "\f1bf";
}

.flaticon-livingroom-lamp:before {
  content: "\f1c0";
}

.flaticon-livingroom-lamp-1:before {
  content: "\f1c1";
}

.flaticon-livingroom-lamp-of-black-design:before {
  content: "\f1c2";
}

.flaticon-livingroom-objects-in-library:before {
  content: "\f1c3";
}

.flaticon-livingroom-plant-pot-with-bamboo:before {
  content: "\f1c4";
}

.flaticon-livingroom-rack-furniture:before {
  content: "\f1c5";
}

.flaticon-livingroom-rocking-chair:before {
  content: "\f1c6";
}

.flaticon-livingroom-small-tall-table-with-seats-set:before {
  content: "\f1c7";
}

.flaticon-livingroom-storage-furniture:before {
  content: "\f1c8";
}

.flaticon-livingroom-table:before {
  content: "\f1c9";
}

.flaticon-livingroom-table-with-a-small-jar:before {
  content: "\f1ca";
}

.flaticon-livingroom-window-with-curtains:before {
  content: "\f1cb";
}

.flaticon-long-studio-ruler-tool:before {
  content: "\f1cc";
}

.flaticon-magazines-drawer-of-grid-design:before {
  content: "\f1cd";
}

.flaticon-mail-box:before {
  content: "\f1ce";
}

.flaticon-microwave-or-small-oven-kitchen-cooking-tool:before {
  content: "\f1cf";
}

.flaticon-mixer:before {
  content: "\f1d0";
}

.flaticon-mixer-1:before {
  content: "\f1d1";
}

.flaticon-mixer-machine-with-jar:before {
  content: "\f1d2";
}

.flaticon-modern-livingroom-furniture-shapes:before {
  content: "\f1d3";
}

.flaticon-mop-cleaning-tool-for-house-floors:before {
  content: "\f1d4";
}

.flaticon-mop-tool-to-clean-floors:before {
  content: "\f1d5";
}

.flaticon-mouse-with-cord:before {
  content: "\f1d6";
}

.flaticon-mower-machine:before {
  content: "\f1d7";
}

.flaticon-opened-window-door:before {
  content: "\f1d8";
}

.flaticon-opened-window-door-of-glasses:before {
  content: "\f1d9";
}

.flaticon-organizer-box-for-storage-of-things:before {
  content: "\f1da";
}

.flaticon-ornamental-candles-for-livingroom-decoration:before {
  content: "\f1db";
}

.flaticon-ornamental-fruits-bowl:before {
  content: "\f1dc";
}

.flaticon-oven:before {
  content: "\f1dd";
}

.flaticon-oven-electric-tool:before {
  content: "\f1de";
}

.flaticon-paintbrush-garage-tool:before {
  content: "\f1df";
}

.flaticon-painting-roller-tool:before {
  content: "\f1e0";
}

.flaticon-pepper-grinder:before {
  content: "\f1e1";
}

.flaticon-phone-tool-of-home:before {
  content: "\f1e2";
}

.flaticon-picture-with-frame-for-livingroom-decoration-of-house:before {
  content: "\f1e3";
}

.flaticon-pipes-angles:before {
  content: "\f1e4";
}

.flaticon-plant-in-a-pot:before {
  content: "\f1e5";
}

.flaticon-plant-on-a-pot:before {
  content: "\f1e6";
}

.flaticon-plant-pot-with-flower-for-livingroom-ornamentation:before {
  content: "\f1e7";
}

.flaticon-plants-pots:before {
  content: "\f1e8";
}

.flaticon-plug:before {
  content: "\f1e9";
}

.flaticon-queen-size-bed:before {
  content: "\f1ea";
}

.flaticon-radio-rectangular-shape:before {
  content: "\f1eb";
}

.flaticon-rake:before {
  content: "\f1ec";
}

.flaticon-rake-fork-for-gardening:before {
  content: "\f1ed";
}

.flaticon-razor:before {
  content: "\f1ee";
}

.flaticon-refrigerator:before {
  content: "\f1ef";
}

.flaticon-salt-and-pepper-containers-for-dining-room:before {
  content: "\f1f0";
}

.flaticon-sewing-machine:before {
  content: "\f1f1";
}

.flaticon-short-shovel-to-sweep:before {
  content: "\f1f2";
}

.flaticon-shovel-to-clean-house-floors:before {
  content: "\f1f3";
}

.flaticon-shower-bathroom-tool:before {
  content: "\f1f4";
}

.flaticon-small-bench:before {
  content: "\f1f5";
}

.flaticon-spotted-beetle-insect-of-a-garden:before {
  content: "\f1f6";
}

.flaticon-square-framed-mirror:before {
  content: "\f1f7";
}

.flaticon-squeezer:before {
  content: "\f1f8";
}

.flaticon-steering-wheel:before {
  content: "\f1f9";
}

.flaticon-stones-floor:before {
  content: "\f1fa";
}

.flaticon-striped-house:before {
  content: "\f1fb";
}

.flaticon-striped-stick-of-yard:before {
  content: "\f1fc";
}

.flaticon-studio-box-like-bag-for-storage:before {
  content: "\f1fd";
}

.flaticon-studio-calculator:before {
  content: "\f1fe";
}

.flaticon-studio-carpets-couple-with-papers:before {
  content: "\f1ff";
}

.flaticon-studio-compass-drawing-tool:before {
  content: "\f200";
}

.flaticon-studio-desk-and-chair:before {
  content: "\f201";
}

.flaticon-studio-desk-with-table-chair-computer-tower-and-monitor:before {
  content: "\f202";
}

.flaticon-studio-desk-with-two-drawers-and-a-computer-monitor-on-it:before {
  content: "\f203";
}

.flaticon-studio-furniture-of-two-drawers-on-wheels:before {
  content: "\f204";
}

.flaticon-studio-printed-paper:before {
  content: "\f205";
}

.flaticon-studio-printing-machine:before {
  content: "\f206";
}

.flaticon-studio-semicircular-tool-to-measure-angles-degrees:before {
  content: "\f207";
}

.flaticon-studio-table-silhouette-perspective:before {
  content: "\f208";
}

.flaticon-studio-tablet-computer-tool:before {
  content: "\f209";
}

.flaticon-studio-trays-with-papers:before {
  content: "\f20a";
}

.flaticon-studio-triangular-ruler-tool:before {
  content: "\f20b";
}

.flaticon-studio-writing-tools:before {
  content: "\f20c";
}

.flaticon-swimming-pool-perspective-with-water:before {
  content: "\f20d";
}

.flaticon-table-and-two-chairs-set-for-back-yard:before {
  content: "\f20e";
}

.flaticon-three-candles-candelabrum-of-livingroom-ornamentation:before {
  content: "\f20f";
}

.flaticon-toothbrush:before {
  content: "\f210";
}

.flaticon-toothbrush-1:before {
  content: "\f211";
}

.flaticon-two-burners-from-top-view:before {
  content: "\f212";
}

.flaticon-two-single-beds-and-a-small-table-in-the-middle-of-bedroom-furniture:before {
  content: "\f213";
}

.flaticon-umbrella:before {
  content: "\f214";
}

.flaticon-vacuum:before {
  content: "\f215";
}

.flaticon-vintage-turntable-audio-tool:before {
  content: "\f216";
}

.flaticon-vintage-water-pump:before {
  content: "\f217";
}

.flaticon-wall-socket-of-three-holes-in-rectangular-shape:before {
  content: "\f218";
}

.flaticon-washing-machine:before {
  content: "\f219";
}

.flaticon-water-closet:before {
  content: "\f21a";
}

.flaticon-water-tap-on-pipes:before {
  content: "\f21b";
}

.flaticon-watering-can-silhouette:before {
  content: "\f21c";
}

.flaticon-watering-pipes-for-gardening:before {
  content: "\f21d";
}

.flaticon-wheelbarrow-tool-for-yard:before {
  content: "\f21e";
}

.flaticon-window-building-detail:before {
  content: "\f21f";
}

.flaticon-wine-bottles-on-a-rack:before {
  content: "\f220";
}

.flaticon-wine-glass:before {
  content: "\f221";
}

.flaticon-wood-fence-of-yard:before {
  content: "\f222";
}

.flaticon-wrench-silhouette-in-horizontal-position:before {
  content: "\f223";
}

.flaticon-yard-bird-feeder:before {
  content: "\f224";
}

.flaticon-yard-deck-chair:before {
  content: "\f225";
}

.flaticon-yard-fountain:before {
  content: "\f226";
}

.flaticon-yard-hosepipe-in-spiral:before {
  content: "\f227";
}

.flaticon-yard-pot-with-saguaro-cactus:before {
  content: "\f228";
}

.flaticon-yard-scissors-for-gardening:before {
  content: "\f229";
}

.flaticon-yard-stone-floor-architectonic-detail:before {
  content: "\f22a";
}

.flaticon-yard-tool-shape:before {
  content: "\f22b";
}

.flaticon-yard-tree-in-a-pot:before {
  content: "\f22c";
}

.flaticon-yard-tree-of-circular-shape-in-a-pot:before {
  content: "\f22d";
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url("assets/template1/fonts/CooperHewitt-Light.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root {
  --ck-image-style-spacing: 1.5em;
}

body {
  height: 100%;
  background: #fff;
  border: 0;
  color: #4c4c4c;
  min-width: 320px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Delvatore
Version:    1.0

[Table of contents]

1. Body
2. Header top wrapper / .top-wrapper
3. Slider area / #home
4. About us section / #about
5. Rooms section / #rooms
6. Best places section / #best-places
7. Testimonials / #what-client-say
8. Latest news from blog / #latest-news
9. Footer logo and social network links / .bot1-wrapper
10. Contact info / .bot2-wrapper
11. Copyright section / .bot3-wrapper
-------------------------------------------------------------------*/
.guests-popover {
  background-color: white;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid var(--main-color);
  width: 24%;
}
.guests-popover.bs-popover-top, .guests-popover.bs-popover-auto[x-placement^=top] {
  margin-bottom: 0;
}
.guests-popover.bs-popover-bottom, .guests-popover.bs-popover-auto[x-placement^=bottom] {
  margin-top: 0;
}
.guests-popover .arrow {
  display: none;
}

/* Global properties ======================================================== */
a[href^="tel:"] {
  color: inherit;
  text-decoration: none;
}

::selection {
  background: var(--main-color) !important;
  color: #fff;
}

::-moz-selection {
  background: var(--main-color) !important;
  color: #fff;
}

a:not(.nocolor) {
  color: var(--main-color);
  text-decoration: underline;
  outline: none;
}
a:not(.nocolor):hover {
  color: var(--main-dark-color);
  text-decoration: none;
  outline: none;
}

#main {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.clearfix2:before,
.clearfix2:after {
  content: " ";
  display: table;
}

.clearfix2:after {
  clear: both;
}

/* ============================= header ====================== */
.search-wrapper {
  display: none;
  position: fixed;
  background: #fff;
  z-index: 200;
  width: 100%;
  height: 64px;
  left: 0;
  top: 0;
}

.search-opened .search-wrapper {
  display: block;
}

.search-inner {
  position: relative;
}

.search-btn {
  display: block;
  text-decoration: none;
  width: 81px;
  height: 64px;
  line-height: 64px;
  border-left: 1px solid rgba(246, 174, 0, 0.2);
  color: #333333;
  text-align: center;
  font-size: 24px;
}

.search-btn:hover {
  color: var(--main-color);
}

.search-close {
  position: absolute;
  right: 0;
  top: 0;
}

.search-field {
  padding-right: 100px;
  border-left: 1px solid rgba(246, 174, 0, 0.2);
  padding-left: 10px;
}

.search-field .form-control {
  border: none;
  font-size: 16px;
  padding: 20px 0;
  outline: none;
  box-shadow: none;
  color: #333;
}

.top-wrapper {
  position: relative;
  z-index: 60;
}

.top-wrapper-dark {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  background: rgb(247, 247, 247);
}

.top-inner {
  position: relative;
  background: #fff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
}

.top-wrapper-dark .top-inner {
  background: none;
  box-shadow: none;
}

.top1 {
  /*border-bottom: 1px solid rgba(246, 174, 0, 0.2);*/
  position: relative;
}

.logo_wrapper {
  display: inline-block;
}

.top2 {
  position: relative;
}

a.dropdown-toggle {
  display: block;
  font-size: 14px;
  text-decoration: none;
  line-height: 64px;
  padding: 0px 30px;
  color: #333333;
}

.dropdown-menu {
  margin: 0;
  padding: 0;
  border: none;
  background: #fff;
  border-radius: 0;
  border-top: 2px solid var(--main-color);
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-menu .dropdown-item {
  border-top: 1px solid #dddddd;
  text-decoration: none;
  display: block;
  padding: 15px 30px;
  color: #333333;
  font-size: 13px;
  line-height: 20px;
}

.dropdown-menu .dropdown-item:hover {
  background: var(--main-color);
  color: #fff;
}

.dropdown-menu .dropdown-item:first-child {
  border-top: none;
}

.dropdown-menu .dropdown-item img {
  margin-right: 30px;
  vertical-align: top;
  border-radius: 2px;
}

.currency {
  float: left;
}

.currency {
  width: 150px;
  text-align: center;
}

.currency .dropdown-menu {
  width: 100%;
  min-width: 0;
}

.currency .dropdown-menu .dropdown-item {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.language {
  float: left;
  /*border-left: 1px solid rgba(246, 174, 0, 0.2);*/
}

.language .dropdown-menu {
  width: 270px;
}

.search-open {
  float: right;
}

.top-wrapper-dark .search-open {
  color: #fff;
}

.social-wrapper {
  float: right;
  padding: 0 20px;
  border-left: 1px solid rgba(246, 174, 0, 0.2);
}

.social {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.social li {
  display: inline-block;
  padding: 0 5px;
}

.social a {
  display: block;
  color: #333333;
  line-height: 64px;
  transition: all 0.3s ease-out;
}

.social a:hover {
  color: var(--main-color);
}

.top-wrapper-dark .social a {
  color: #fff;
}

.top-wrapper-dark .social a:hover {
  color: var(--main-color);
}

.account {
  float: right;
}

.account a.dropdown-toggle {
  padding-left: 0;
}

.account .dropdown-menu {
  width: 100%;
}

.account .dropdown-toggle img {
  border-radius: 50%;
  border: 2px solid var(--main-color);
}

.top-wrapper-dark .account .dropdown-toggle img {
  border-color: #fff;
}

header {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 30;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/* ============================= content ====================== */
p {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 300;
  color: #2d2d2d;
}

.bot1 p {
  color: #999999;
}

.breadcrumbs1_wrapper {
  position: relative;
}

.breadcrumbs1 {
  padding: 15px 0;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color);
  font-size: 14px;
}

.breadcrumbs1 a {
  color: #8c8c8c;
  text-decoration: none;
  display: inline-block;
}

.breadcrumbs1 a:hover {
  color: #000;
  text-decoration: none;
}

.breadcrumbs1 span {
  display: inline-block;
  margin: 0 10px;
  color: #8c8c8c;
}

#content {
  position: relative;
  padding-top: 25px;
  padding-bottom: 100px;
  min-height: calc(100vh - 250px);
}

.page-not-found {
  text-align: center;
}

.page-not-found .txt1 {
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  color: #242d2e;
  padding: 50px 0;
}

.page-not-found .txt1 span {
  color: var(--main-color);
}

.page-numbers-wrapper {
  position: relative;
  background: #f8f8f8;
  padding: 55px 0;
}

.department1 {
  position: relative;
  text-align: center;
}

.department1 figure {
  position: relative;
  display: inline-block;
  margin-bottom: 25px;
}

.department1 .caption {
  font-family: "Oswald", sans-serif;
  font-size: 25px;
  color: #313131;
  font-weight: 400;
  letter-spacing: 2px;
}

.yjsg-round-progress {
  width: 155px;
  height: 155px;
  font-size: 40px;
  color: #383838;
  font-family: "Oswald", sans-serif;
  border-top-color: var(--main-color);
  border-bottom-color: #fff;
}

#our-team {
  position: relative;
  padding: 60px 0 30px;
}

.our-team-wrapper {
  position: relative;
}

.our-team-inner {
  position: relative;
}

.our-team {
  position: relative;
  text-align: center;
  background: #fff;
  margin-bottom: 30px;
  transition: all 0.3s ease-out;
}

.our-team:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
}

.our-team figure {
  position: relative;
  margin: 0;
}

.our-team figure img {
  width: 100%;
  position: relative;
}

.our-team .caption {
  padding: 20px 10px;
}

.our-team .caption .txt1 {
  font-size: 20px;
  color: #242d2e;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
}

.our-team .caption .txt2 {
  font-size: 12px;
  color: #a4aeb0;
  font-family: "Oswald", sans-serif;
  letter-spacing: 3px;
}

.our-team .social3-wrapper {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.our-team:hover .social3-wrapper {
  opacity: 1;
}

.social3-wrapper {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 10%;
  text-align: center;
}

.social3 {
  display: inline-block;
  padding: 10px;
  margin: 0;
  list-style: none;
  background: #fff;
}

.social3 li {
  display: inline-block;
  padding: 0 15px;
}

.social3 a {
  display: block;
  color: #647072;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.3s ease-out;
}

.social3 a:hover {
  color: var(--main-color);
}

.contact-location-wrapper {
  position: relative;
  padding-left: 150px;
  margin-bottom: 50px;
}

#google_map {
  position: relative;
  height: 600px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  border: 15px solid #fff;
}

.contact-location {
  position: absolute;
  background: #fff;
  left: 0;
  top: 50%;
  width: 430px;
  box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 40px;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.contact-location .txt1 {
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  color: #999999;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 30px;
}

.contact-location .txt2 {
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  color: #333333;
  text-transform: uppercase;
  line-height: 1.3;
}

.contact-location .txt3 {
  color: #6b6b6b;
  font-size: 11px;
}

.contact-location .txt4 {
  position: relative;
  font-size: 12px;
  margin: 30px 0;
  padding: 40px 0;
}

.contact-location .txt4:before {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  top: 0;
}

.contact-location .txt4:after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  bottom: 0;
}

.dl1 {
  padding: 0;
  margin: 0;
  font-size: 13px;
}

.dl1 dt {
  float: left;
  width: 100px;
  overflow: hidden;
  clear: left;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #969696;
  font-weight: 600;
}

.dl1 dd {
  margin-left: 180px;
}

#content-wrapper {
  position: relative;
  overflow: hidden;
}

#content-wrapper:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: 240px;
}

#content-inner {
  position: relative;
  padding-top: 25px;
  padding-bottom: 100px;
}

.blog-search {
  position: relative;
  background: #fff;
  padding: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 45px;
}

.blog-search .form-control {
  border: none;
  border-radius: 0;
  color: #999999;
  padding: 17px 25px;
}

.blog-search .form-control:focus {
  box-shadow: none;
  moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.blog-search a {
  position: absolute;
  right: 0px;
  top: 0px;
  text-align: center;
  width: 60px;
  line-height: 60px;
  color: #999999;
  background: none;
}

.blog-search a:hover {
  color: var(--main-color);
  background: none;
}

.blog-search a i {
  font-size: 22px;
  line-height: 60px;
}

.sidebar-block {
  margin-bottom: 35px;
}

.sidebar-block:last-child {
  margin-bottom: 0px;
}

.sidebar-title {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #333333;
  font-size: 20px;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 0px;
}

.ul2 {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.ul2 li {
  display: block;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #dddddd;
}

.ul2 li:first-child {
  border-top: 1px solid #dddddd;
}

.ul2 li a {
  display: block;
  position: relative;
  font-size: 15px;
  padding: 10px 60px 10px 25px;
  text-decoration: none;
  color: #333333;
  transition: all 0.3s ease-out;
}

.ul2 li a:hover {
  color: var(--main-color);
}

.ul2 li a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 15px;
  pointer-events: none;
  content: "\f138";
  font-family: "Font Awesome 5 Pro";
  line-height: 1;
  font-weight: 900;
}

.ul2 li a span {
  position: absolute;
  right: 5px;
  text-align: right;
  top: 10px;
  color: #999999;
}

.latest-tweet {
  padding-bottom: 15px;
}

.latest-tweet:last-child {
  padding-bottom: 0px;
}

.latest-tweet a {
  display: block;
  text-decoration: none;
}

.latest-tweet a .txt1 {
  font-size: 15px;
  color: #1f1f1f;
  padding-bottom: 5px;
  font-weight: 500;
  transition: all 0.3s ease-out;
}

.latest-tweet a:hover .txt1 {
  color: var(--main-color);
}

.latest-tweet a .txt2 {
  color: #1a1a1a;
  line-height: 1.4;
  padding-bottom: 2px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
  transition: all 0.3s ease-out;
}

.latest-tweet a:hover .txt2 {
  color: #000;
}

.latest-tweet a .txt3 {
  color: var(--main-color);
  font-size: 12px;
  position: relative;
  padding-left: 20px;
  transition: all 0.3s ease-out;
}

.latest-tweet a .txt3:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 5px;
  pointer-events: none;
  content: "\f14b";
  font-family: "Font Awesome 5 Pro";
  line-height: 1;
  font-weight: 900;
}

.latest-tweet a:hover .txt3 {
  color: #4c4c4c;
}

.recent-post1 {
  margin-bottom: 20px;
}

.recent-post1:last-child {
  margin-bottom: 0px;
}

.recent-post1 a {
  display: block;
  text-decoration: none;
}

.recent-post1 a figure {
  float: left;
  margin-right: 15px;
  margin-bottom: 0;
}

.recent-post1 a .caption {
  display: table;
  padding-top: 5px;
}

.recent-post1 a .caption .txt1 {
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  padding-bottom: 10px;
  transition: all 0.3s ease-out;
}

.recent-post1 a:hover .caption .txt1 {
  color: var(--main-color);
}

.recent-post1 a .caption .txt2 {
  font-size: 11px;
  color: #6a6a6a;
  position: relative;
  padding-left: 20px;
  transition: all 0.3s ease-out;
}

.recent-post1 a:hover .caption .txt2 {
  color: #000;
}

.recent-post1 a .caption .txt2:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 5px;
  pointer-events: none;
  content: "\f14b";
  font-family: "Font Awesome 5 Pro";
  line-height: 1;
  font-weight: 900;
}

.tags {
  display: block;
  padding: 0;
  margin: 0;
  line-height: 1;
}

.tags li {
  display: inline-block;
  padding-right: 10px;
  padding-bottom: 6px;
  vertical-align: top;
}

.tags li a {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: #999999;
  font-size: 14px;
  padding: 0;
  line-height: 1;
  transition: all 0.3s ease-out;
}

.tags li a:hover {
  color: #000;
}

.post {
  position: relative;
  margin-bottom: 50px;
}

.post-header {
  padding-bottom: 5px;
}

.post-image img {
  width: 100%;
}

.post-video {
  position: relative;
}

.post-video .img-inner {
  position: relative;
  padding-bottom: 56.25%;
}

.post-video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: none;
}

.post-additional {
  padding-bottom: 15px;
  color: #818181;
  font-size: 13px;
}

.post-story-body {
  padding-bottom: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 300;
}

.post-more a {
  font-size: 15px;
  text-transform: uppercase;
  color: #4c4c4c;
  font-weight: 600;
  text-decoration: underline;
  transition: all 0.3s ease-out;
}

.post-more a:hover {
  color: var(--main-color);
}

.blockquote1 {
  padding: 0;
  margin: 35px 0 0;
  border: 1px solid #ebebeb;
  padding: 50px 30px;
  font-size: 15px;
  color: #4c4c4c;
  font-style: italic;
  text-align: center;
  font-weight: 300;
  position: relative;
}

.blockquote1:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 5px;
  pointer-events: none;
  content: "\f10d";
  font-family: "Font Awesome 5 Pro";
  line-height: 1;
  font-style: normal;
  color: var(--main-color);
  font-size: 28px;
  line-height: 30px;
  top: -15px;
  padding: 0 10px;
  background: #fff;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  text-align: center;
  font-weight: 900;
}

.comment-block {
  margin-bottom: 30px;
}

.comment-block figure {
  float: left;
  margin-right: 30px;
}

.comment-block .caption {
  overflow: hidden;
}

.comment-block .caption .txt1 {
  padding-bottom: 5px;
  font-weight: 600;
  color: #333333;
  font-size: 14px;
}

.comment-block .caption .txt1 span {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  margin: 0 35px;
}

.comment-block .caption .txt2 {
  font-size: 12px;
  padding-bottom: 10px;
}

.comment-block .caption .txt3 {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.pane1 {
  margin-bottom: 15px;
}

.tabs2-wrapper {
  display: inline-block;
  vertical-align: top;
  padding-right: 25px;
  margin-bottom: 15px;
}

.tabs2-wrapper .tabs {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 0;
  font-size: 0;
  vertical-align: top;
}

.tabs2-wrapper .tabs li {
  display: inline-block;
  margin-right: 5px;
}

.tabs2-wrapper .tabs li a {
  display: block;
  background: #f4f4f4;
  line-height: 34px;
  padding: 0;
  width: 34px;
  text-align: center;
  color: #333e48;
  font-size: 14px;
}

.tabs2-wrapper .tabs li a:hover,
.tabs2-wrapper .tabs li.active a {
  color: #fff;
  background: var(--main-dark-color);
}

.sorting {
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
  margin-bottom: 15px;
}

.sorting a.dropdown-toggle {
  background: #f4f4f4;
  line-height: 34px;
}

.sorting .dropdown-menu {
  width: 100%;
}

.sortby {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 15px;
}

.sortby a.dropdown-toggle {
  background: #f4f4f4;
  line-height: 34px;
}

.sortby .dropdown-menu {
  width: 100%;
}

.showing-results {
  float: right;
  color: var(--main-dark-color);
  padding-top: 7px;
  font-size: 13px;
}

.showing-results span {
  color: #333333;
}

.pagination {
  float: left;
  display: block;
  border-radius: 0;
  margin-bottom: 0;
}

.page-item {
  display: inline-block;
  margin: 0 4px;
}

.page-link {
  margin-left: 0;
  background: #f4f4f4;
  border-radius: 0%;
  font-size: 14px;
  text-align: center;
  width: 35px;
  line-height: 35px;
  border: none;
  padding: 0;
  text-decoration: none;
  color: #333333;
}

.page-link:hover {
  background: var(--main-dark-color);
  color: #fff;
}

.page-item.active .page-link {
  background: var(--main-color);
  color: #fff;
}

.room-wide-wrapper {
  position: relative;
}

.room-wide-inner {
  position: relative;
}

.room-wide {
  position: relative;
  margin-bottom: 35px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}

.room-wide figure {
  margin-right: 40px;
  margin-bottom: 0;
  width: 100%;
}

.room-wide figure img {
  width: 400px;
}

.room-wide .caption {
  padding-top: 10px;
}

.room-wide-left {
  float: left;
  width: 70%;
  padding-right: 7%;
}

.room-wide-right {
  float: right;
  width: 30%;
  text-align: right;
}

.room-wide .caption .txt1 {
  font-size: 9px;
  color: #646464;
  padding-bottom: 5px;
}

.room-wide .caption .txt2 {
  font-size: 27px;
  font-family: "Oswald", sans-serif;
  color: #333333;
  text-transform: uppercase;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.room-wide .caption .txt2:after {
  content: "";
  display: block;
  width: 50px;
  height: 1px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  bottom: -1px;
}

.room-wide-price {
  border-left: 2px solid var(--main-color);
  line-height: 1.4;
  padding-top: 5px;
  padding-left: 10px;
}

.room-wide .caption .txt3 {
  font-family: "Oswald", sans-serif;
  font-size: 32px;
  color: var(--main-dark-color);
  text-transform: uppercase;
}

.room-wide .caption .txt4 {
  text-transform: uppercase;
  font-size: 9px;
  color: #999999;
  letter-spacing: 2px;
}

.room-wide .caption .txt5 {
  padding-top: 20px;
  padding-bottom: 15px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.room-wide .caption .txt6 {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.room-wide .caption .txt6.card-detail {
  position: relative;
  border-bottom: unset;
  padding-bottom: unset;
  margin-bottom: unset;
}

.room-wide .caption .txt6:after {
  content: "";
  display: block;
  width: 50px;
  height: 1px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  bottom: -1px;
}

.room-wide .caption .txt6 a {
  text-decoration: underline;
  display: inline-block;
  position: relative;
  font-size: 13px;
  color: var(--main-color);
  font-weight: 600;
  transition: all 0.3s ease-out;
}

.room-wide .caption .txt6.card-detail:after {
  content: "";
  display: block;
  width: 50px;
  height: 1px;
  background: unset;
  position: absolute;
  left: 0;
  bottom: -1px;
}

.room-wide .caption .txt6 a:hover {
  color: var(--main-dark-color);
}

.room-wide .caption .txt6 a i {
  font-size: 20px;
  padding-left: 18px;
  vertical-align: middle;
}

.room-wide .caption .txt7 {
  text-transform: uppercase;
  font-size: 9px;
  color: #3e3e3e;
  letter-spacing: 1px;
  padding-top: 5px;
  margin-bottom: 20px;
}

.room-wide .caption .txt7 span {
  color: var(--main-dark-color);
}

.room-wide .caption .txt8 img {
  margin: 0 5px 5px 0;
  max-width: 42px;
}

.room-wide .caption .txt9 a {
  display: block;
  text-align: center;
  text-decoration: none;
  background: var(--main-dark-color);
  color: #fff;
  text-transform: uppercase;
  padding: 11px 3px;
  border-radius: 3px;
  font-size: 13px;
  transition: all 0.3s ease-out;
}

.room-wide .caption .txt9 a:hover {
  background: var(--main-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.room-wide .caption .txt9.added a {
  display: block;
  text-align: center;
  text-decoration: none;
  background: var(--main-color);
  color: #fff;
  text-transform: uppercase;
  padding: 11px 3px;
  border-radius: 3px;
  font-size: 13px;
  transition: all 0.3s ease-out;
}

.our-rooms-wrapper {
  margin-bottom: 50px;
}

.our-rooms-left {
  float: left;
  width: 50%;
  position: relative;
  padding-left: 85px;
  padding-right: 25px;
}

.right .our-rooms-left {
  float: right;
  padding-left: 25px;
  padding-right: 85px;
}

.our-rooms-right {
  float: left;
  width: 50%;
  padding-left: 25px;
  position: relative;
}

.right .our-rooms-right {
  padding-left: 0px;
  padding-right: 25px;
}

.our-rooms-img {
  background: #fff;
  border: 15px solid #fff;
  box-shadow: 0px 5px 27px 0px rgba(0, 0, 0, 0.1);
  margin: 0;
  position: relative;
}

.our-rooms-img img {
  width: 100%;
}

.our-rooms-thumb {
  position: absolute;
  left: 0;
  top: 50%;
  border: 15px solid #fff;
  box-shadow: 0px 5px 27px 0px rgba(0, 0, 0, 0.1);
  margin: 0;
  width: 100px;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.right .our-rooms-thumb {
  left: auto;
  right: 0;
}

.our-rooms-caption {
  padding-right: 110px;
  padding-top: 20px;
  min-height: 370px;
}

.right .our-rooms-caption {
  padding-right: 0;
  padding-left: 110px;
  text-align: right;
}

.our-rooms-caption .txt1 {
  font-size: 11px;
  color: #646464;
  padding-bottom: 0px;
}

.our-rooms-caption .txt2 {
  font-size: 25px;
  font-family: "Oswald", sans-serif;
  color: #333333;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.our-rooms-caption .txt3 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #000;
  padding-bottom: 15px;
}

.our-rooms-caption .txt4 {
  font-size: 9px;
  color: #3e3e3e;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 20px;
}

.our-rooms-caption .txt4 span {
  background: var(--main-dark-color);
  display: inline-block;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  margin: 0 3px;
  border-radius: 3px;
  font-weight: bold;
}

.our-rooms-icon .our-rooms-icon-txt1 {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}

.our-rooms-icon .our-rooms-icon-txt2 {
  color: #6e6e6e;
  font-size: 11px;
}

.our-rooms-details {
  position: absolute;
  right: 0;
  top: 0;
}

.right .our-rooms-details {
  right: auto;
  left: 0;
}

.our-rooms-details a {
  display: block;
  text-decoration: none;
  position: relative;
}

.our-rooms-details a .caption {
  display: block;
  position: relative;
  width: 75px;
  height: 320px;
  background: #fff;
  border-radius: 0px;
  margin-left: auto;
  color: #676767;
  border: 1px solid #b7b7b7;
  transition: all 0.3s ease-out;
}

.our-rooms-details a:hover .caption {
  background: var(--main-dark-color);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-color: var(--main-dark-color);
}

.right .our-rooms-details a .caption {
  margin-left: 0;
}

.our-rooms-details a .caption .txt1 {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  text-align: center;
  font-size: 22px;
}

.our-rooms-details a .caption .txt2 {
  position: absolute;
  left: 46px;
  top: 30px;
  font-size: 11px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 3px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.our-rooms-details a .txt3 {
  padding-top: 30px;
  color: #333333;
  text-transform: uppercase;
  font-size: 10px;
}

.our-rooms-details a .txt3 i {
  font-size: 13px;
  color: var(--main-color);
}

.slider2-wrapper {
  position: relative;
  overflow: hidden;
  padding-right: 225px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.slider-for2 {
  position: relative;
  border: 15px solid #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.slider-for2 .slider-item {
  outline: none;
}

.slider-for2 img {
  width: 100%;
}

.slider-nav2 {
  position: absolute;
  right: 5px;
  top: 5px;
  min-width: 190px;
}

.slider-nav2 .slick-list {
  overflow: visible;
}

.slider-nav2 .slider-item {
  outline: none;
  float: none;
  border: none;
  padding-bottom: 23px;
}

.slider-nav2 .slider-item .slider-item-inner {
  position: relative;
}

.slider-nav2 .slider-item:hover img {
  border-color: var(--main-color);
}

.slider-nav2 .slider-item.slick-current img {
  border-color: var(--main-color);
}

.details-wrapper {
  min-height: 490px;
  padding-right: 200px;
  position: relative;
  margin-bottom: 0px;
}

.details-wrapper .txt1 {
  font-size: 10px;
  color: #646464;
  text-transform: uppercase;
}

.details-wrapper .txt2 {
  color: #333333;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  padding-bottom: 25px;
}

.details-wrapper .txt21 {
  color: #999999;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  padding-bottom: 25px;
}

.details-wrapper .txt3 {
  font-family: "Roboto", sans-serif;
  position: relative;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 17px 0;
  font-size: 15px;
  font-weight: 300;
}

.details-wrapper .txt3:before {
  content: "";
  width: 50px;
  height: 1px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  top: -1px;
}

.details-wrapper .txt3:after {
  content: "";
  width: 50px;
  height: 1px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  bottom: -1px;
}

.details-caption {
  display: block;
  position: absolute;
  right: 4px;
  top: 0;
  width: 138px;
  height: 490px;
  background: #fff;
  border-radius: 0px;
  margin-left: auto;
  color: #9a9a9a;
  border: 1px solid #b7b7b7;
}

.details-caption .txt1 {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 35px;
  text-align: center;
  font-size: 25px;
}

.details-caption .txt2 {
  position: absolute;
  left: 78px;
  top: 55px;
  font-size: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 3px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.details-caption .txt21 {
  position: absolute;
  left: 78px;
  top: 55px;
  font-size: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 3px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.details2-wrapper {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.details2-wrapper:after {
  content: "";
  width: 50px;
  height: 1px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  bottom: -1px;
}

.details2-info {
  text-align: right;
  padding-top: 30px;
}

.check1 {
  background: url(/assets/template1/images/check1.png) right 3px top no-repeat;
  padding: 3px 35px 3px 0;
  color: #474747;
  font-size: 10px;
  text-transform: uppercase;
  min-height: 23px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.check1 span {
  color: var(--main-dark-color);
}

.details2-link {
  display: inline-block;
  position: relative;
  text-align: center;
  background: var(--main-dark-color);
  color: #fff;
  line-height: 70px;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 0 50px;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.details2-link:hover {
  background: var(--main-color);
  color: #fff;
}

.details2-link:before {
  content: "";
  display: block;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  border: 1px dashed #fff;
  position: absolute;
  border-radius: 5px;
}

.details2-link .txt1 {
  font-size: 30px;
  position: relative;
  transition: all 0.3s ease-out;
}

.details2-link:hover .txt1 {
  opacity: 0;
}

.details2-link .txt1 span {
  font-size: 12px;
  letter-spacing: 2px;
  display: inline-block;
  vertical-align: middle;
}

.details2-link .txt2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  font-size: 16px;
  text-decoration: underline;
  letter-spacing: 1px;
  transition: all 0.3s ease-out;
}

.details2-link:hover .txt2 {
  opacity: 1;
}

.page-details #google_map {
  position: relative;
  height: 300px;
  box-shadow: none;
  border: 15px solid #e5e5e5;
  margin-bottom: 40px;
}

.share-to-frends-wrapper {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 18px 0;
  margin-bottom: 50px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.share-to-friends {
  float: left;
}

.share-to-friends-txt {
  display: inline-block;
  padding-right: 15px;
  vertical-align: top;
  text-transform: uppercase;
  font-weight: bold;
}

.share-to-friends-icons {
  display: inline-block;
  vertical-align: top;
}

.social4 {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.social4 li {
  display: inline-block;
  margin: 0px;
  border-left: 1px solid #eeeeee;
  float: left;
}

.social4 li:first-child {
  border-left: none;
}

.social4 li a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  color: #333e48;
  text-align: center;
  line-height: 18px;
  padding: 0 15px;
  transition: all 0.3s ease-out;
}

.social4 li a:hover {
  color: var(--main-color);
}

.blog-tags {
  float: right;
}

.blog-tags-txt {
  display: inline-block;
  vertical-align: top;
  padding-right: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.blog-tags-tags {
  display: inline-block;
  vertical-align: top;
  color: #9f9f9f;
  font-style: italic;
}

.blog-tags-tags a {
  color: #626262;
  text-decoration: none;
}

.blog-tags-tags a:hover {
  color: var(--main-color);
}

.comment-block2 {
  margin-bottom: 30px;
  padding-bottom: 60px;
  position: relative;
  border-bottom: 1px solid #eeeeee;
}

.comment-block2:after {
  content: "";
  width: 50px;
  height: 1px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  bottom: -1px;
}

.comment-block2 figure {
  float: left;
  margin-right: 20px;
}

.comment-block2 figure img {
  border-radius: 50%;
  border: 3px solid #ee9c43;
}

.comment-block2 .caption {
  overflow: hidden;
  font-size: 14px;
  padding-top: 10px;
}

.comment-block2 .caption .txt1 {
  padding-bottom: 10px;
  font-weight: 700;
  color: #333333;
  font-size: 14px;
}

.comment-block2 .caption .txt2 {
  font-style: italic;
  font-size: 13px;
}

.home {
  position: relative;
  z-index: 1;
  padding: 0 !important;
}

.home-inner {
  position: relative;
  z-index: 1;
}

#slides_wrapper {
  background: #000;
  z-index: 1;
}

.slide-text-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: 55%;
  z-index: 2;
  margin-top: -40px;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slide-text {
  text-align: center;
}

.slide-text .img1 img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.slide-text .txt1 {
  padding-top: 30px;
}

.slide-text .txt1 span {
  font-size: 110px;
  color: #fff;
  font-family: "Oswald", sans-serif;
  margin: 0 30px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1;
}

.slide-text .txt1 i {
  display: block;
  text-align: left;
  font-size: 0;
  line-height: 0;
  padding-left: 6px;
}

.slide-buttons-wrapper {
  border-top: 1px solid #034960;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 7%;
  z-index: 3;
  margin-bottom: 100px;
}

.slide-buttons {
  text-align: center;
  margin-top: -18px;
}

.slide-button {
  display: inline-block;
  padding: 0 20px;
  min-width: 160px;
}

.slide-button a {
  display: block;
  text-decoration: none;
  position: relative;
}

.slide-button a .ic {
  display: inline-block;
  background: #034960;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.slide-button a:hover .ic {
  background: var(--main-color);
  border-color: var(--main-color);
}

.slide-button a .hr {
  width: 80px;
  height: 3px;
  background: var(--main-dark-color);
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 16px;
  z-index: -1;
  transition: all 0.3s ease-out;
}

.slide-button a:hover .hr {
  background: var(--main-color);
}

.slide-button a .txt1 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding-top: 10px;
  transition: all 0.3s ease-out;
}

.slide-button a:hover .txt1 {
  color: var(--main-color);
}

.booking2-wrapper {
  position: relative;
  margin-bottom: 40px;
}

.booking-inner {
  position: relative;
  background: #fff;
  border-radius: 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
}

/* ============================= select2 start ====================== */
.form1 {
  display: block;
  padding: 0;
  position: relative;
}

.form1 label {
  display: none;
}

.form1 .col1 {
  float: left;
  width: 23%;
}

.form1 .col2 {
  float: left;
  width: 14%;
}

.form1 .col3 {
  float: left;
  width: 18%;
}

.form1 .col4 {
  float: left;
  width: 22%;
}

.form1 .text_wrapper {
  font-size: 13px;
  line-height: 19px;
  padding: 23px 20px 23px 25px;
  position: relative;
  cursor: pointer;
}

.form1 .c1 {
  border-right: 1px solid #dddddd;
}

.form1 .c2 {
  border-right: 1px solid #dddddd;
}

.form1 .c3 {
  border-right: 1px solid #dddddd;
}

.form1 .c4 {
  border-right: 1px solid #dddddd;
}

.form1 .c5 {
  border-right: 1px solid #dddddd;
}

.ui-datepicker {
  font-size: 14px;
  z-index: 100 !important;
}

.btn-form1-submit {
  display: block;
  padding: 0;
  border: none;
  margin: 0;
  width: 100%;
  background: var(--main-color);
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 65px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.btn-form1-submit:hover {
  background: var(--main-dark-color);
}

.btn-form1-submit:focus {
  outline: none;
}

.btn-form1-submit:after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  background: inherit;
}

.input1_inner {
  position: relative;
  position: relative;
}

.input1_inner:after {
  content: "";
  display: block;
  position: absolute;
  right: 30px;
  top: 25px;
  pointer-events: none;
  content: "\f073";
  font-family: "Font Awesome 5 Pro";
  font-size: 15px;
  color: var(--main-color);
  line-height: 1;
  font-weight: 900;
}

.input1_inner input {
  display: block;
  width: 100%;
  font-size: 13px;
  color: #333333;
  padding: 23px 50px 23px 30px;
  background: none;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.input1_inner input:focus {
  outline: none;
  box-shadow: none;
}

.select1_wrapper {
  margin-bottom: 0;
}

.select1_inner {
  position: relative;
}

.select1_inner select {
  height: 65px;
  padding: 23px 20px 23px 20px;
  font-size: 13px;
  color: #4c4c4c;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
}

.input1_inner input {
  height: 65px;
}

.select1_inner:after,
.text_wrapper:after {
  content: "";
  display: block;
  position: absolute;
  right: 15px;
  top: 25px;
  pointer-events: none;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-size: 13px;
  color: #333;
  line-height: 15px;
  font-weight: 900;
}

/* ============================= select2 end ====================== */
#about {
  position: relative;
  padding: 50px 0 70px;
}

.page-about #about {
  padding-top: 25px;
}

.about-slider-left {
  float: left;
  width: 50%;
  position: relative;
  padding-left: 80px;
  padding-right: 25px;
}

.about-slider-right {
  float: left;
  width: 50%;
  padding-left: 25px;
}

.about-slider-thumb {
  position: absolute;
  left: 0;
  top: 50%;
  border: 15px solid #fff;
  box-shadow: 0px 5px 27px 0px rgba(0, 0, 0, 0.1);
  margin: 0;
  width: 95px;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slider-for {
  background: #fff;
  border: 15px solid #fff;
  box-shadow: 0px 5px 27px 0px rgba(0, 0, 0, 0.1);
}

.slider-for .slider-item {
  outline: none;
}

.slider-for img {
  width: 100%;
}

.slider-nav .slider-item {
  outline: none;
  padding-top: 20px;
}

.slider-nav .txt1 {
  font-family: "Oswald", sans-serif;
  font-size: 32px;
  color: #333333;
  line-height: 1.2;
  padding-bottom: 30px;
}

.slider-nav .txt2 {
  padding-bottom: 30px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}

.slider-nav .txt3 {
  color: #333333;
  text-transform: uppercase;
}

.slider-nav .txt4 {
  color: #333333;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
}

.slider-nav .txt5 {
  color: #f8b300;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
}

#rooms {
  position: relative;
  padding: 50px 0 30px;
  background: #f8f8f8;
}

.room-wrapper {
  position: relative;
}

.room-inner {
  position: relative;
}

.room {
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  margin-right: 50px;
  margin-bottom: 50px;
}

.room figure {
  margin: 0;
  position: relative;
}

.room figure img {
  position: relative;
  width: 100%;
}

.room figure figcaption {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 10%;
  padding: 0 50px 0 50px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 1px;
}

.room figure figcaption .txt1 {
  font-size: 18px;
  font-family: "Oswald", sans-serif;
}

.room figure figcaption .txt2 {
  font-size: 10px;
}

.room .caption {
  padding: 30px 50px 30px 50px;
}

.room .caption .txt1 {
  font-size: 19px;
  font-family: "Oswald", sans-serif;
}

.room .caption .txt2 {
  font-size: 9px;
  color: #646464;
  padding-bottom: 8px;
}

.small-stars {
  display: inline-block;
  color: #feb200;
  padding-left: 10px;
}

.room .caption .txt3 {
  font-size: 13px;
  color: #5f5f5f;
  padding-bottom: 15px;
  font-family: "Roboto", sans-serif;
}

.room .caption .txt4 a {
  text-decoration: underline;
  display: inline-block;
  position: relative;
  font-size: 12px;
  color: var(--main-color);
  font-weight: 600;
  transition: all 0.3s ease-out;
}

.room .caption .txt4 a:hover {
  color: var(--main-dark-color);
}

.room .caption .txt4 a i {
  font-size: 16px;
  padding-left: 18px;
  vertical-align: middle;
}

.room .select-txt {
  position: relative;
}

.room .select-txt a {
  display: block;
  text-decoration: underline;
  padding: 15px 60px 15px 50px;
  background: var(--main-color);
  color: #fff;
  font-size: 16px;
  transition: all 0.3s ease-out;
}

.room .select-txt a:hover {
  background: var(--main-dark-color);
}

.room .select-txt a span {
  display: inline-block;
  position: relative;
  padding-right: 30px;
}

.room .select-txt a i {
  position: absolute;
  top: 6px;
  right: 0;
  transition: all 0.3s ease-out;
}

.room .select-txt a:hover i {
  margin-right: -10px;
}

.room .room-icons {
  position: absolute;
  width: 75px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  position: absolute;
  right: -38px;
  bottom: 0;
  text-align: center;
}

.room-price {
  background: var(--main-dark-color);
  color: #fff;
  text-transform: uppercase;
  padding: 16px 2px;
}

.room-price .txt1 {
  font-size: 20px;
  font-family: "Oswald", sans-serif;
}

.room-price .txt1 span {
  font-weight: 500;
  font-family: "Oswald", sans-serif;
}

.room-price .txt2 {
  font-size: 9px;
}

.room-ic {
  position: relative;
  text-align: center;
  border-bottom: 1px solid #dcdcdc;
  text-transform: uppercase;
  color: #999999;
  font-size: 9px;
  line-height: 1.2;
  padding: 17px 2px;
}

.room-ic i {
  color: #555555;
  padding-bottom: 5px;
}

.room-ic.room-ic-wifi i {
  font-size: 25px;
}

.room-ic.room-ic-person i {
  font-size: 18px;
}

.room-ic.room-ic-breakfast i {
  font-size: 25px;
}

.room-ic.room-ic-left .txt0 {
  font-size: 30px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: #555555;
}

.room-ic.room-ic-refund i {
  font-size: 20px;
}

.room-ic.room-ic-discount .txt0 {
  font-size: 30px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: #555555;
}

.room-ic.room-ic-discount .txt0 span {
  font-size: 14px;
}

#best-places {
  position: relative;
  padding: 50px 0 120px 0;
  overflow: hidden;
}

#best-places .title1 {
  text-align: left;
  padding-bottom: 0px;
  font-size: 30px;
}

#best-places .title2 {
  text-align: left;
  background: none;
  font-size: 13px;
  margin-bottom: 20px;
}

.owl-carousel-wide .owl-stage-outer {
  overflow: visible;
}

.owl-carousel-wide .owl-dots {
  display: none;
}

.place-wrapper {
  position: relative;
}

.place-inner {
  position: relative;
}

.place {
  position: relative;
  background: #fff;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.3);
  min-height: 570px;
}

.place .caption {
  float: left;
  width: 55%;
  padding: 50px;
}

.place .figure {
  width: 45%;
  position: absolute;
  right: 50px;
  top: 50px;
  padding-left: 50px;
  margin: 0;
}

.place .figure img {
  border: 15px solid #fff;
  width: 100%;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.3);
  display: block;
}

.place .caption .txt1 {
  font-size: 12px;
  color: #444444;
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: 50px;
}

.place .caption .text-block1 {
  line-height: 1.2;
  padding-bottom: 40px;
}

.place .caption .text-block1-inner {
  display: inline-block;
  border-right: 2px solid var(--main-color);
  padding-right: 20px;
}

.place .caption .txt2 {
  font-family: "Oswald", sans-serif;
  font-size: 33px;
  color: #333333;
}

.place .caption .txt3 {
  font-size: 12px;
  color: #333333;
  text-transform: uppercase;
  margin-top: 5px;
  line-height: 18px;
}

.place .caption .txt3 i {
  color: var(--main-color);
  padding-right: 10px;
}

.place .caption .txt4 {
  border-top: 1px solid #eeeeee;
  padding-top: 50px;
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  position: relative;
  padding-bottom: 50px;
}

.place .caption .txt4:before {
  content: "";
  display: block;
  width: 50px;
  height: 1px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  top: -1px;
}

.rest1 {
  display: inline-block;
  position: relative;
  padding-right: 20px;
  padding-bottom: 15px;
}

.rest1 figure {
  margin: 0 15px 0 0;
  float: left;
}

.rest1 .rest1-caption {
  display: table;
  line-height: 1.2;
}

.rest1 .rest1-caption .rest1-txt1 {
  font-size: 20px;
  color: #333333;
  font-weight: 700;
}

.rest1 .rest1-caption .rest1-txt2 {
  text-transform: uppercase;
  color: #7f7f7f;
  font-size: 13px;
}

#what-client-say {
  position: relative;
  background: #f8f8f8;
  padding: 50px 0;
}

.testimonial-wrapper {
  position: relative;
}

.testimonial-inner {
  position: relative;
}

.testimonial {
  position: relative;
}

.testimonial .testimonial-caption {
  background: #fff;
  padding: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  font-style: italic;
  position: relative;
  margin-bottom: 30px;
  transition: all 0.3s ease-out;
}

.testimonial .testimonial-caption:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  left: 60px;
  top: 100%;
  transition: all 0.3s ease-out;
}

.center .testimonial .testimonial-caption {
  background: var(--main-color);
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.3);
  font-weight: 300;
  color: #fff;
}

.center .testimonial .testimonial-caption:after {
  border-color: var(--main-color) transparent transparent transparent;
}

.testimonial .author figure {
  float: left;
  margin: 0 15px 0 35px;
  width: 70px;
}

.testimonial .author figure img {
  border-radius: 50%;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
}

.testimonial .author .caption {
  display: table;
  padding-top: 10px;
}

.testimonial .author .caption .txt1 {
  color: #333333;
  font-size: 15px;
  font-weight: bold;
}

.testimonial .author .caption .txt2 {
  color: #7d7d7d;
  font-size: 12px;
  font-style: italic;
}

.owl-carousel-testimonials .owl-dots {
  text-align: center;
  padding-top: 50px;
}

.owl-carousel-testimonials button.owl-dot {
  display: inline-block;
  background: var(--main-dark-color);
  width: 16px;
  height: 16px;
  margin: 0 10px;
  vertical-align: middle;
  border: 4px solid #f8f8f8;
  transition: all 0.3s ease-out;
}

.owl-carousel-testimonials button.owl-dot:focus {
  outline: none;
}

.owl-carousel-testimonials button.owl-dot.active,
.owl-carousel-testimonials button.owl-dot:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  width: 16px;
  height: 16px;
  margin: 0 10px;
}

#latest-news {
  position: relative;
  padding: 50px 0 80px;
}

.latest-news-wrapper {
  position: relative;
}

.latest-news-inner {
  position: relative;
}

.latest-news {
  position: relative;
}

.latest-news figure {
  border: 15px solid #fff;
  background: #fff;
  margin: 0;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.3);
  margin: 0 0 -45px 0;
}

.latest-news figure img {
  width: 100%;
}

.latest-news .caption {
  background: #fff;
  position: relative;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.3);
  margin-left: 15px;
  margin-right: 15px;
  padding: 20px 10%;
}

.latest-news .caption .txt1 {
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  color: #333333;
}

.latest-news .caption .txt1 a {
  color: #333333;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.latest-news .caption .txt1 a:hover {
  color: var(--main-color);
}

.latest-news .caption .txt2 {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #000;
  letter-spacing: -0.09px;
}

/* ============================= footer ====================== */
.bot1-wrapper {
  background: #333333;
}

.bot1 {
  padding: 60px 0 35px;
  text-align: center;
  color: #a4aeb0;
}

.social2 {
  display: inline-block;
  margin: 30px 0 20px;
  padding: 0;
  list-style: none;
}

.social2 li {
  display: inline-block;
  margin: 0px;
  float: left;
}

.social2 li:first-child {
  border-left: none;
}

.social2 li a {
  display: block;
  text-decoration: none;
  font-size: 14px;
  color: #a4aeb0;
  text-align: center;
  line-height: 28px;
  width: 30px;
  border: 1px solid #a4aeb0;
  border-radius: 50%;
  margin: 5px 10px;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-out;
}

.social2 li a:hover {
  color: var(--main-color);
  border-color: var(--main-color);
}

.social2 li a:before {
  display: block;
  content: "";
  width: 6px;
  top: -4px;
  bottom: -4px;
  background: #333333;
  position: absolute;
  z-index: -1;
  left: 50%;
  margin-left: -3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.bot2-wrapper {
  background: #333333;
}

.bot2 {
  border-top: 1px solid #484848;
  border-bottom: 1px solid #484848;
  padding: 20px 0 40px;
}

.bot2-title {
  text-transform: uppercase;
  color: var(--main-color);
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  padding: 30px 0 20px;
}

.contact1 {
  padding-bottom: 17px;
  color: #a4aeb0;
}

.contact1 a {
  color: #a4aeb0;
}

.contact1 b {
  color: var(--main-color);
}

.recent-post {
  padding-bottom: 20px;
}

.recent-post a {
  display: block;
  text-decoration: none;
  line-height: 1.5;
}

.recent-post a figure {
  float: left;
  margin: 0 15px 0 0;
}

.recent-post a .caption {
  display: table;
}

.recent-post a .caption .txt1 {
  color: #fff;
  transition: all 0.3s ease-out;
}

.recent-post a:hover .caption .txt1 {
  color: #a4aeb0;
}

.recent-post a .caption .txt2 {
  color: #a4aeb0;
  font-size: 14px;
  transition: all 0.3s ease-out;
}

.recent-post a:hover .caption .txt2 {
  color: var(--main-color);
}

.bot3-wrapper {
  background: #333333;
  padding: 40px 0;
  text-align: center;
  color: #808080;
}

.best1 {
  padding: 0;
  margin: 0;
  list-style: none;
}

.best1 li {
  display: block;
  float: left;
  padding: 0 22px 20px 0;
}

.best1 li a {
  display: block;
  background: var(--main-color);
}

.best1 li a img {
  position: relative;
  transition: all 0.3s ease-out;
}

.best1 li a:hover img {
  opacity: 0.7;
}

.menu-bot {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
  color: #a4aeb0;
}

.menu-bot li {
  display: inline-block;
  padding: 5px 0;
}

.menu-bot li a {
  display: inline-block;
  font-family: "Oswald", sans-serif;
  color: #a4aeb0;
  text-decoration: none;
}

.menu-bot li a:hover {
  color: var(--main-color);
}

.menu-bot li:before {
  content: "|";
  display: inline-block;
  padding: 0 10px 0 8px;
}

.menu-bot li:first-child:before {
  display: none;
}

.copyrights {
  padding-top: 10px;
  font-size: 14px;
  color: #808080;
}

.copyrights i {
  color: var(--main-color);
}

.copyrights a {
  color: var(--main-color);
  text-decoration: none;
}

/* ============================= main menu ====================== */
.navbar_ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  float: right;
  padding: 0;
  position: static;
  padding: 0 !important;
}

.navbar_ .navbar-toggler {
  float: right;
  padding: 4px 4px;
  font-size: 20px;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 25px;
  margin-bottom: 25px;
}

.top-wrapper-dark .navbar_ .navbar-toggler {
  border-color: rgba(0, 0, 0, 0);
}

.navbar_ .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar_ .navbar-collapse {
  clear: both;
  flex-grow: 0;
}

.navbar_ .navbar-nav {
  display: block;
  padding: 0;
  margin: 0;
}

.navbar_ .navbar-nav > li {
  display: block;
  float: left;
  white-space: nowrap;
  margin-left: 20px;
}

.navbar_ .navbar-nav > li:first-child {
  margin-left: 0;
}

.navbar_ .navbar-nav > li > .nav-link i {
  padding-left: 3px;
}

.top-wrapper-dark .navbar_ .navbar-nav > li > .nav-link {
  line-height: 80px;
}

.navbar_ .navbar-nav > li > .nav-link {
  display: block;
  text-decoration: none;
  font-family: "Oswald", sans-serif;
  font-size: 19px;
  line-height: 110px;
  padding: 0 10px;
  margin: 0;
  color: #333333;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-out;
}

.navbar_ .navbar-nav > li > .nav-link:focus {
  color: #333333;
  border-bottom-color: transparent;
}

.navbar_ .navbar-nav > li > .nav-link:hover,
.navbar_ .navbar-nav > li.sfHover > .nav-link,
.navbar_ .navbar-nav > li.sfHover > .nav-link:hover,
.navbar_ .navbar-nav > li > .nav-link.active,
.navbar_ .navbar-nav > li > .nav-link.active:hover,
.navbar_ .navbar-nav > li > .nav-link.active:focus {
  color: #333333;
  border-bottom-color: var(--main-color);
}

@media (max-width: 767px) {
  .navbar_ .navbar-nav > li > .nav-link:hover,
.navbar_ .navbar-nav > li.sfHover > .nav-link,
.navbar_ .navbar-nav > li.sfHover > .nav-link:hover,
.navbar_ .navbar-nav > li > .nav-link.active,
.navbar_ .navbar-nav > li > .nav-link.active:hover,
.navbar_ .navbar-nav > li > .nav-link.active:focus {
    color: #333333;
  }
}
.navbar_ .navbar-nav > li.sub-menu-2 {
  position: relative;
}

.sf-mega {
  position: absolute;
  left: 50%;
  margin-top: -2px;
  background: #fff;
  border-radius: 0;
  border-top: 2px solid var(--main-color);
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.1);
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.sf-mega ul {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.sf-mega ul li {
  display: block;
  position: relative;
  border-top: 1px solid #dddddd;
}

.sf-mega ul li:first-child {
  border-top: none;
}

.sf-mega ul li a i {
  position: absolute;
  right: 30px;
  top: 18px;
}

.sf-mega ul li a {
  padding: 15px 50px 15px 30px;
  color: #333333;
  font-size: 13px;
  line-height: 20px;
  display: block;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease-out;
}

.sf-mega ul li a:hover {
  background: var(--main-color);
  color: #fff;
}

.sf-mega ul li .sf-mega {
  left: 100%;
  right: auto;
  top: 0;
  margin-top: -2px;
  -ms-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* ============================= main layout ====================== */
h2 {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 30px;
  color: #333333;
  padding: 0;
  margin: 15px 0 10px;
}

h2 a {
  color: #333333;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.title0 {
  text-align: center;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--main-color);
  text-transform: uppercase;
  padding-top: 0px;
  padding-bottom: 0px;
  letter-spacing: 3px;
}

.title1 {
  text-align: center;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 34px;
  color: #333333;
  text-transform: uppercase;
  padding-top: 0px;
  padding-bottom: 0px;
}

.title2 {
  font-size: 15px;
  text-align: center;
  color: #333333;
  font-weight: 400;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.title2::after {
  display: block;
  content: "";
  margin: auto;
  margin-top: 10px;
  height: 2px;
  width: 100%;
  max-width: 100px;
  background-color: var(--main-color);
}

.title3 {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  text-transform: uppercase;
  padding-top: 0px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.title3:after {
  content: "";
  display: block;
  width: 50px;
  height: 1px;
  background: var(--main-color);
  position: absolute;
  left: 0;
  bottom: -1px;
}

.title3 span {
  color: #8c8c8c;
  font-size: 16px;
}

/* === */
/*-----btn-----*/
.btn1 {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 13px 30px;
  text-transform: uppercase;
  position: relative;
  border: none;
  background: #252525;
  letter-spacing: 2px;
  transition: all 0.3s ease-out;
}

.btn1:hover {
  color: #fff;
  background: var(--main-color);
}

.btn2 {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 20px;
  padding: 22px 50px;
  text-transform: uppercase;
  position: relative;
  border: none;
  background: var(--main-dark-color);
  letter-spacing: 1px;
  border-radius: 3px;
  transition: all 0.3s ease-out;
}

.btn2:hover {
  color: #fff;
  background: var(--main-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

/*================= Contacts =========================*/
.notification_error {
  color: #f00;
  padding-bottom: 10px;
}

.notification_ok {
  text-align: left;
  padding-bottom: 10px;
}

#ajax-contact-form .form-group {
  margin-left: 0;
  margin-right: 0;
}

#ajax-contact-form .form-group label {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
}

#ajax-contact-form .form-group label span {
  color: #fcb100;
}

#ajax-contact-form .form-control {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

#ajax-contact-form .form-control:focus {
  border-color: #fcb100;
  background: #fff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(246, 99, 1, 0);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(246, 99, 1, 0);
  color: #000;
}

#ajax-contact-form textarea.form-control {
  height: 126px;
}

#ajax-contact-form .form-group.capthca {
  float: left;
  margin-right: 20px;
}

#ajax-contact-form .form-group.capthca input {
  width: 170px;
}

#ajax-contact-form .form-group.img {
  float: left;
}

#ajax-contact-form .form-group.img img {
  width: 170px;
  height: 38px;
}

.btn-default.btn-cf-submit {
  font-family: "Roboto", sans-serif;
  display: block;
  color: #fff;
  border: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 15px 35px;
  text-decoration: none;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 0px;
  background: var(--main-dark-color);
  position: relative;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  float: right;
  transition: all 0.3s ease-out;
}

.btn-default.btn-cf-submit:hover {
  color: #fff;
  background: #fcb100;
}

/*================= Contacts3 =========================*/
.notification_error3 {
  color: #f00;
  padding-bottom: 10px;
}

.notification_ok3 {
  text-align: left;
  padding-bottom: 10px;
}

#ajax-contact-form3 .form-group {
  margin-left: 0;
  margin-right: 0;
}

#ajax-contact-form3 .form-group label {
  color: #333333;
  font-weight: 400;
  display: none;
}

#ajax-contact-form3 .form-group label span {
  color: #d74c4c;
}

#ajax-contact-form3 .form-control {
  padding: 8px 15px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

#ajax-contact-form3 .form-control:focus {
  border-color: var(--main-color);
  background: #fff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(246, 99, 1, 0);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(246, 99, 1, 0);
  color: #000;
}

#ajax-contact-form3 textarea.form-control {
  height: 180px;
}

#ajax-contact-form3 .form-group.capthca {
  float: left;
  margin-right: 20px;
}

#ajax-contact-form3 .form-group.capthca input {
  width: 170px;
}

#ajax-contact-form3 .form-group.img {
  float: left;
}

#ajax-contact-form3 .form-group.img img {
  width: 170px;
  height: 38px;
}

.btn-default.btn-cf-submit3 {
  font-family: "Roboto", sans-serif;
  display: block;
  color: #fff;
  border: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding: 15px 35px;
  text-decoration: none;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 0px;
  background: var(--main-color);
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.btn-default.btn-cf-submit3:hover {
  color: #fff;
  background: var(--main-dark-color);
}

/*----- media queries ------*/
@media (max-width: 1430px) {
  .sf-mega ul li .sf-mega {
    left: auto;
    right: 100%;
  }
}
@media (max-width: 1259.98px) {
  .details-wrapper {
    min-height: 490px;
    padding-right: 200px;
    position: relative;
    margin-bottom: 30px;
  }
  .about-slider-left {
    padding-left: 65px;
  }
  .about-slider-thumb {
    width: 80px;
  }
  .room .select-txt a {
    padding-left: 20px;
    font-size: 14px;
  }
  .room .caption .txt3 {
    font-size: 14px;
    color: #7e7e7e;
    padding-bottom: 15px;
    font-family: "Roboto", sans-serif;
  }
  .room .caption {
    padding-left: 20px;
  }
  .room figure figcaption {
    padding-left: 20px;
  }
  .room .caption .txt1 {
    font-size: 18px;
  }
  .room .room-ic {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .place .caption {
    width: 50%;
  }
  .place .figure {
    width: 50%;
  }
  #content-wrapper:before {
    margin-left: 200px;
  }
  .room-wide .caption .txt2 {
    font-size: 22px;
  }
  .room-wide .caption .txt3 {
    font-size: 26px;
  }
  .our-rooms-caption .txt2 {
    font-size: 24px;
  }
  .our-rooms-left {
    padding-left: 60px;
  }
  .our-rooms-thumb {
    width: 75px;
  }
  .right .our-rooms-left {
    padding-right: 60px;
  }
  .page-not-found .txt1 {
    padding: 60px 0;
  }
  #about {
    padding: 50px 0;
  }
  #rooms {
    padding: 50px 0 50px;
  }
  #best-places {
    padding: 50px 0;
  }
  #what-client-say {
    padding: 50px 0;
  }
  #latest-news {
    padding: 50px 0;
  }
  #content {
    padding: 25px 0 80px;
  }
  .page-about #about {
    padding-top: 60px;
  }
  .page-numbers-wrapper {
    padding: 80px 0 50px;
  }
  #our-team {
    padding: 100px 0 70px;
  }
  #content-inner {
    padding: 60px 0 80px;
  }
  .blog-search {
    margin-bottom: 50px;
  }
  .booking2-wrapper {
    margin-bottom: 30px;
  }
  .room {
    margin-bottom: 40px;
  }
  .room-wide {
    margin-bottom: 40px;
  }
  .our-rooms-wrapper {
    margin-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .room-wide figure {
    max-width: 400px;
  }
}
@media (max-width: 991.98px) {
  .social-wrapper {
    display: none;
  }
  .navbar_ .navbar-nav > li {
    margin-left: 10px;
  }
  .navbar_ .navbar-nav > li > .nav-link {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
  }
  .btn-form1-submit:after {
    background: none;
  }
  .slide-text .txt1 span {
    font-size: 70px;
    margin-right: 17px;
  }
  .slide-text .txt1 i {
    display: none;
  }
  .slide-buttons-wrapper {
    margin-bottom: 140px;
  }
  .about-slider-left {
    width: auto;
    padding-right: 0;
    float: none;
  }
  .about-slider-right {
    padding-left: 0;
    width: auto;
    float: none;
    padding-top: 20px;
  }
  .about-slider-left {
    padding-left: 95px;
  }
  .about-slider-thumb {
    width: 110px;
  }
  .place {
    padding: 50px;
  }
  .place .caption {
    float: none;
    padding: 0;
    width: auto;
  }
  .place .figure {
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
    padding-left: 0;
  }
  #content-wrapper:before {
    margin-left: 150px;
  }
  .room-wide figure {
    float: none;
    width: auto;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .room-wide figure img {
    width: 100%;
  }
  .our-rooms-left {
    float: none;
    width: auto;
    padding-right: 0;
    padding-left: 95px;
    margin-bottom: 30px;
  }
  .our-rooms-thumb {
    width: 110px;
  }
  .our-rooms-right {
    float: none;
    padding-left: 0;
    width: auto;
  }
  .slider-nav2 .slider-item {
    padding-bottom: 20px;
  }
  .share-to-friends {
    float: none;
    padding-bottom: 10px;
  }
  .blog-tags {
    float: none;
  }
  .right .our-rooms-left {
    float: none;
    padding-left: 0;
    padding-right: 95px;
  }
  .right .our-rooms-right {
    padding-right: 0;
  }
  .page-not-found .txt1 {
    padding: 50px 0;
  }
  #about {
    padding: 80px 0;
  }
  #rooms {
    padding: 80px 0 30px;
  }
  #best-places {
    padding: 80px 0;
  }
  #what-client-say {
    padding: 80px 0;
  }
  #latest-news {
    padding: 80px 0;
  }
  #content {
    padding: 20px 0 60px;
  }
  .page-about #about {
    padding-top: 40px;
  }
  .page-numbers-wrapper {
    padding: 60px 0 30px;
  }
  #our-team {
    padding: 80px 0 50px;
  }
  #content-inner {
    padding: 40px 0 60px;
  }
  .blog-search {
    margin-bottom: 40px;
  }
  .booking2-wrapper {
    margin-bottom: 40px;
  }
  .our-rooms-wrapper {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .form1 .col1 {
    width: 50%;
    border-bottom: 1px solid #dddddd;
  }
  .form1 .c2 {
    border-right: none;
  }
  .form1 .col2 {
    width: 25%;
  }
  .form1 .col3 {
    width: 25%;
  }
  .form1 .col4 {
    width: 50%;
  }
  .guests-popover {
    min-width: 344px !important;
  }
  .details2-link {
    padding: 0 15px;
  }
}
@media (max-width: 767.98px) {
  .top1-wrapper {
    display: none;
  }
  .navbar_ {
    float: none;
  }
  .navbar_ .navbar-collapse {
    width: 100%;
    padding: 0;
  }
  .top-wrapper-dark .navbar-collapse {
    background: #f7f7f7;
  }
  .navbar_ .navbar-nav > li > .nav-link:hover,
.navbar_ .navbar-nav > li.sfHover > .nav-link,
.navbar_ .navbar-nav > li.sfHover > .nav-link:hover,
.navbar_ .navbar-nav > li > .nav-link.active,
.navbar_ .navbar-nav > li > .nav-link.active:hover,
.navbar_ .navbar-nav > li > .nav-link.active:focus {
    border-bottom: 2px solid transparent !important;
  }
  .navbar_ .navbar-nav {
    height: auto;
    max-height: calc(100vh - 90px);
    overflow-y: auto;
  }
  .navbar_ .navbar-nav > li {
    float: none;
    margin-left: 0;
  }
  .navbar_ .navbar-nav > li > .nav-link {
    line-height: 40px;
    padding: 0 15px;
  }
  .top-wrapper-dark .navbar_ .navbar-nav > li > .nav-link {
    line-height: 40px;
    color: #333 !important;
  }
  .sf-mega {
    position: relative;
    left: auto;
    right: auto;
    max-width: none;
    top: auto;
    padding: 0;
    box-shadow: none;
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .sf-mega-menu li a {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .navbar_ .navbar-nav > li.sub-menu-2 .sf-mega {
    padding: 0;
  }
  .navbar_ .navbar-nav > li.sub-menu-2 .sf-mega ul li .sf-mega {
    left: 0;
    right: auto;
    margin-left: 0;
    margin-top: 0;
  }
  .form1 .col1 {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #dddddd;
  }
  .form1 .col2 {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #dddddd;
  }
  .form1 .col3 {
    width: 100%;
    border-right: 0;
  }
  .form1 .col4 {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #dddddd;
  }
  .guests-popover {
    min-width: 100% !important;
  }
  .btn-form1-submit:after {
    background: none;
  }
  .slide-buttons-wrapper {
    display: none;
  }
  .slide-text-wrapper {
    margin-top: 0;
  }
  .about-slider-thumb {
    display: none;
  }
  .about-slider-left {
    padding-left: 0;
  }
  .contact-location-wrapper {
    padding-left: 0;
  }
  .contact-location {
    position: relative;
    left: 0;
    top: 0;
    width: auto;
    margin-top: 30px;
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .dl1 dd {
    margin-left: 120px;
  }
  #content-wrapper:before {
    display: none;
  }
  .content-part {
    margin-bottom: 30px;
  }
  .sidebar-part {
    margin-bottom: 30px;
  }
  .our-rooms-thumb {
    display: none;
  }
  .our-rooms-left {
    padding-left: 0;
  }
  .slider-nav2 {
    position: static;
    max-height: 130px;
    white-space: nowrap;
  }
  .slider-nav2 .slider-item {
    display: inline-block;
    width: 150px;
    margin-right: 15px;
  }
  .slider-embed-nav {
    border: 10px solid #fff !important;
  }
  .slider-nav2 .slick-list {
    overflow: hidden;
  }
  .slider2-wrapper {
    position: relative;
    padding: 0;
    overflow: visible;
    display: inline-block;
    overflow: hidden;
    width: 100%;
  }
  .slider-nav2 {
    margin-top: 20px;
  }
  .details-caption {
    display: none;
  }
  .details-wrapper {
    padding-right: 0;
  }
  .right .our-rooms-left {
    padding-right: 0;
  }
  .top-wrapper-dark header {
    display: block;
  }
  .title1 {
    font-size: 26px;
  }
  #best-places .title1 {
    font-size: 24px;
  }
  .slider-nav .txt1 {
    font-size: 26px;
  }
  #best-places .title2 {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
  .place {
    padding: 30px;
  }
  .latest-news-wrapper {
    margin-bottom: 30px;
  }
  .bot1 {
    padding: 30px 0 5px;
  }
  .bot2 {
    padding: 0 0 10px;
  }
  .bot3-wrapper {
    padding: 15px 0;
  }
  .page-not-found .txt1 {
    padding: 40px 0;
    font-size: 24px;
  }
  .department1 .caption {
    font-size: 20px;
  }
  #about {
    padding: 50px 0;
  }
  #rooms {
    padding: 50px 0 10px;
  }
  #best-places {
    padding: 50px 0;
  }
  #what-client-say {
    padding: 50px 0;
  }
  #latest-news {
    padding: 50px 0;
  }
  #content {
    padding: 20px 0 50px;
  }
  .page-about #about {
    padding-top: 20px;
  }
  #our-team {
    padding: 50px 0 30px;
  }
  #content-inner {
    padding: 20px 0 40px;
  }
  .blog-search {
    margin-bottom: 30px;
  }
  .sidebar-block {
    margin-bottom: 30px;
  }
  .post {
    margin-bottom: 30px;
  }
  .post-additional {
    padding-bottom: 10px;
  }
  .post-story-body {
    padding-bottom: 0px;
  }
  .post-header {
    padding-bottom: 10px;
  }
  h2 {
    font-size: 24px;
  }
  .slider2-wrapper {
    margin-bottom: 20px;
  }
  .share-to-frends-wrapper {
    margin-bottom: 30px;
  }
  .comment-block2 {
    padding-bottom: 30px;
  }
  .room {
    margin-bottom: 30px;
  }
  .room-wide {
    margin-bottom: 30px;
  }
  .our-rooms-wrapper {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1260px) {
  .room-wide .caption .txt5 {
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 13px;
    overflow: hidden;
  }
  .room-wide .caption .txt7 {
    text-transform: uppercase;
    font-size: 9px;
    color: #999999;
    letter-spacing: 1px;
    padding-top: 5px;
    margin-bottom: 20px;
  }
  .slider-nav .txt2 {
    padding-bottom: 0px;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
  }
  .slider-nav .txt1 {
    font-family: "Oswald", sans-serif;
    font-size: 25px;
    color: #333333;
    line-height: 1.2;
    padding-bottom: 20px;
  }
}
@media (max-width: 325px) {
  .room-wide .caption .txt8 img {
    margin: 0 4px 0px 0;
    max-width: 42px;
  }
  .page-item {
    display: inline-block;
    margin: 0 1px;
  }
}
@media (max-width: 340px) {
  .room .select-txt a {
    padding-left: 20px;
    font-size: 12px;
  }
}
@media (max-width: 575.98px) {
  .room-wide-left {
    float: none;
    width: auto;
    padding-right: 0;
    margin-bottom: 15px;
  }
  .room-wide-right {
    float: none;
    width: auto;
    text-align: left;
  }
  .dl1 dt {
    float: none;
    width: auto;
  }
  .dl1 dd {
    margin-left: 0;
  }
  .room .caption .txt3 {
    font-size: 12px;
    color: #7e7e7e;
    padding-bottom: 15px;
    font-family: "Roboto", sans-serif;
  }
}
@media (min-height: 768px) and (max-height: 1000px) {
  .slide-text .txt1 {
    padding-top: 30px;
    margin-bottom: 60px;
  }
}
@media (max-height: 680px) {
  .title2 {
    font-size: 14px;
    text-align: center;
    color: #333333;
    padding-bottom: 20px;
    margin-bottom: 25px;
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#slides .slides-container {
  background-image: url(/assets/template1/images/background.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

#slides {
  position: relative;
  background: #000;
  z-index: 1;
  opacity: 0.75;
}

#slides .scrollable {
  *zoom: 1;
  position: relative;
  top: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

#slides .scrollable:after {
  content: "";
  display: table;
  clear: both;
}

.slides-navigation a {
  position: absolute;
  z-index: 10;
  display: block;
  width: 60px;
  height: 60px;
  text-decoration: none;
  top: 50%;
  margin-top: -30px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 60px;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.slides-navigation a.prev {
  left: 3%;
}

.slides-navigation a.next {
  right: 3%;
}

.slides-navigation a:hover {
  background-color: #fff;
  color: #1f1f1f;
}

.slides-pagination {
  position: absolute;
  z-index: 3;
  bottom: 5%;
  text-align: center;
  width: 100%;
}

.slides-pagination a {
  width: 17px;
  height: 17px;
  display: inline-block;
  text-indent: -100%;
  overflow: hidden;
  background: #fff;
  margin: 0 5px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.17);
  moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.17);
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.slides-pagination a:hover,
.slides-pagination a.current {
  background: #f2bf02;
}

.slides-container .caption {
  position: absolute;
  width: 100%;
  top: 50%;
  text-align: left;
  color: #fff;
  line-height: 1;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.active-slider {
  border-color: var(--main-color) !important;
}

.slider-embed-nav {
  border: 15px solid #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  transition: all 0.3s ease-out;
}

.list-group-item-action {
  color: #495057;
}

.btn {
  padding: 11px 15px;
  border-radius: 3px;
  transition: all 0.3s ease-out;
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
}

.btn-primary {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.btn-primary:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.btn-file {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 0;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  display: block;
  cursor: pointer;
}

.date-box {
  text-align: center;
  line-height: 1.2;
  padding: 0.25em 0.5em;
  background-color: rgb(242, 242, 242);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  min-width: 5em;
}
.date-box .small {
  font-size: 0.8em;
}
.date-box .day {
  font-size: 2em;
}

.cursor-pointer {
  cursor: pointer;
}

.list-group-item.active {
  color: #fff;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.login-register-form {
  width: 100%;
  max-width: 500px;
  padding: 15px;
}

.btn {
  border: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--main-dark-color);
  border-color: var(--main-dark-color);
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 39, 39, 0.1) !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--main-darker-color);
  border-color: var(--main-darker-color);
}

.icon-action {
  cursor: pointer;
  padding: 0.5rem !important;
  color: #65686b;
  transition: 0.2s;
}
.icon-action:hover {
  color: #474a4d;
}

.carousel-control-prev,
.carousel-control-next {
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: inherit;
  opacity: 1;
}

.page-content h2,
.page-content h3,
.page-content h4 {
  color: #a7a7a7;
}
.page-content h2 {
  font-family: inherit;
  font-size: 1.3em;
}
.page-content h3 {
  font-size: 1.2em;
}
.page-content h4 {
  font-size: 1.1em;
}
.page-content h1,
.page-content h2 {
  margin-bottom: 20px;
}
.page-content h3 {
  margin-bottom: 10px;
}
.page-content .image {
  margin: auto;
}
.page-content .image img {
  width: 100%;
}
.page-content .image > figcaption {
  caption-side: bottom;
  word-break: break-word;
  color: #333;
  background-color: #f7f7f7;
  padding: 0.6em;
  text-align: center;
  outline-offset: -1px;
}
.page-content .image-style-side,
.page-content .image-style-align-left,
.page-content .image-style-align-center,
.page-content .image-style-align-right {
  max-width: 50%;
}
.page-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
.page-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
.page-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
.page-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
.page-content .text-tiny {
  font-size: 0.7em;
}
.page-content .text-small {
  font-size: 0.85em;
}
.page-content .text-big {
  font-size: 1.4em;
}
.page-content .text-huge {
  font-size: 1.8em;
}
.page-content table {
  width: 100%;
}
.page-content table td {
  border-top: none;
}

.page-content table {
  color: #a7a7a7;
  display: block;
}
.page-content table tr {
  display: flex;
}
.page-content table tr td {
  text-align: left;
  display: block;
  flex-grow: 1;
  flex-basis: 0;
}

@media (max-width: 767px) {
  .page-content table {
    color: #a7a7a7;
    display: block;
  }
  .page-content table tr {
    display: block;
  }
  .page-content table tr td {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .image {
    width: 100% !important;
    max-width: 100% !important;
    float: none !important;
    margin: 0 !important;
  }
}
.bg-primary {
  background-color: var(--main-color) !important;
}

.ngb-dp-weekday {
  color: #5bc0de;
  color: var(--main-color) !important;
}

.btn-link {
  color: var(--main-color) !important;
}

.ngb-dp-month-name {
  font-size: 1em !important;
}

.page-item .page-link {
  text-decoration: none;
}

.home-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-container {
  color: #a7a7a7;
  font-size: 0.9em;
}
.footer-container p {
  color: #a7a7a7;
}
.footer-container a {
  text-decoration: none;
}

html, body {
  height: 100%;
}

:root {
  --main-color: #fab100;
  --main-dark-color: #e6a100;
}

.select2 {
  width: 100%;
}

.select2 *:focus {
  outline: none !important;
}

.select2-container {
  min-width: 150px;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 500px;
}

.select2-container--default .select2-selection--single {
  background: none;
  border-radius: 0;
  border: none;
  height: auto;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 13px;
  line-height: 19px;
  padding: 23px 20px 23px 20px;
  color: #333;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none;
}

.select2-dropdown {
  background: none;
  border-radius: 0;
  z-index: 100;
  min-width: 0px;
  border: none;
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: 2px solid var(--main-color);
}

.select2-container--open .select2-dropdown--below {
  border-top: 2px solid var(--main-color);
}

.select2-results__option {
  padding: 15px 30px;
  background: #fff;
  font-size: 13px;
  line-height: 20px;
  color: #333333;
  border-top: 1px solid #dddddd;
}

.select2-results__option:first-child {
  border-top: none;
}

.small .select2-results__option {
  padding-left: 0;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: var(--main-color);
  color: #fff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: var(--main-color);
  color: #fff;
}

.iti {
  width: 100%;
}

#loading-bar .bar {
  background: var(--main-color) !important;
}

.form-group .error {
  display: none;
}

.ng-dirty.ng-invalid + .error {
  display: block;
}